import React, { useContext, useEffect, useState} from "react";
import stepModule from '../Step.module.css'
import NextButton from "../../NextButton/NextButton";
import DynamicObject from "../../../../models/dynamic-object";
import {RedirectionStep as RedirectionStepModel} from '../../../../models'
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import RefType from "../../../../models/ref-type";
import Step from "../Step";
import {useTranslation} from "react-i18next";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import {getDataStorage} from "../../../../helpers/storage.helper";
import LanguageContext from "../../../../storage/LanguageContext";
import {useNavigate} from "react-router-dom";
import PostContext from "../../../../storage/PostContext";
import {PointOfSales} from "../../../../helpers/PointOfSales";
import {generateAuthToken} from "../../../../helpers/auth.helper";

interface Props {
    children?: React.ReactNode;
    nextApi?: UseHttpResponse<DynamicObject>;
    config: RedirectionStepModel,
}

interface RefDataType extends RefType<DynamicObject> {
}


const RedirectionStep = React.forwardRef<RefDataType, Props>((props, ref): JSX.Element => {
    const {config, nextApi} = props
    const {t} = useTranslation();
    const storage = getDataStorage()
    const langCtx=useContext(LanguageContext)
    const containerWidth=document.getElementById('content-container')
    const postCtx=useContext(PostContext)
    const navigate=useNavigate()
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);
    const [token, setToken] = useState<string>("");
    const getToken = async () => {
        return generateAuthToken()
    };

    const urlParams = new URLSearchParams(window.location.search);
    const pathname = window.location.pathname;
    const handleRedirection=()=>{
        let flow = urlParams.get("flow");
        if (flow) {
            sessionStorage.setItem("flowType", flow);
        } else {
            flow = sessionStorage.getItem("flowType");
            sessionStorage.removeItem("flowType");
        }
        let pos = "";
        const isPending = pathname.includes("pending-payment");
        if (flow && flow === "edit") {
            pos = isPending
                ? PointOfSales.EDIT_LISTING_POS_PENDING
                : PointOfSales.EDIT_LISTING_POS_SUCCESS;
        } else if (flow && flow === "activate") {
            if (postCtx.data.isOverLimit)
                pos = PointOfSales.MYLISTINGVIEW_PENDINGPAYMENT_ACTIVATELISTING;
            else pos = PointOfSales.MYLISTINGVIEW_DELETED_LISTING_ACTIVATELISTING;
        } else {
            pos = isPending
                ? PointOfSales.ADD_LISTING_POS_PENDING
                : PointOfSales.ADD_LISTING_POS_SUCCESS;
        }
        getToken().then((token) => {
            setToken(token ?? "");
            if (postCtx?.data?.post_id && postCtx?.data?.step?.deeplink)
                navigate(
                    `?flow=promoteFlow&post_id=${postCtx?.data?.post_id}&pos=${pos}&actionSource=add`,
                    { replace: true }
                );
        });
    }
    useEffect(() => {
        //handle redirection of deep link
        handleRedirection()

        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        //scroll to of the page in normal step
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        })

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once


    return (
        <Step>
            {!storage.isMobile && config.title && <div style={{padding: "0px 14px"}}>
                <p style={{
                    fontWeight: config.title.style?.bold ? "bold" : "normal",
                    fontSize: getSizes(config.title.style?.size),
                    color: config.title.color,
                }}>{config.title.locale}</p>
            </div>}
            <div className={stepModule.content}>{props.children}</div>
        </Step>
    )
})

export default RedirectionStep
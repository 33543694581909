export const FUNNEL_EVENTS = {
    INIT: { eventAction: 'APF_Init', eventCategory: 'Sellers', eventLabel: 'AddPostScreen' },
    PREVIEW: { eventAction: 'APF_Preview', eventCategory: 'Sellers', eventLabel: 'Preview_AddPostScreen' },
    SUBMIT: { eventAction: 'APF_Submit', eventCategory: 'Sellers', eventLabel: 'SubmitBtn_AddPostScreen' },
    SUCCESS: { eventAction: 'APF_Success', eventCategory: 'Sellers', eventLabel: 'API_AddPostScreen' },
    DROP: { eventAction: 'APF_Drop', eventCategory: 'Sellers', eventLabel: 'CloseBtn_AddPostScreen' },
};

const getEventQueue = (): Array<{ eventAction: string; eventCategory: string; eventLabel: string }> => {
    const queue = sessionStorage.getItem('eventQueue');
    return queue ? JSON.parse(queue) : [];
};

const saveEventQueue = (queue: Array<{ eventAction: string; eventCategory: string; eventLabel: string }>) => {
    sessionStorage.setItem('eventQueue', JSON.stringify(queue));
};

export const trackEvent = (event: { eventAction: string; eventCategory: string; eventLabel: string }) => {
    let eventQueue = getEventQueue();
    if (!eventQueue.some((e) => e.eventAction === event.eventAction)) {
        eventQueue.push(event);
        saveEventQueue(eventQueue);
    }
};


export const pushEventsToDataLayer = () => {
    let eventQueue = getEventQueue();
    eventQueue.forEach((event) => {
        window.dataLayer.push({
            event: "UserInteraction",
            eventAction: event.eventAction,
            eventCategory: event.eventCategory,
            eventLabel: event.eventLabel,
        });
    });
    sessionStorage.removeItem('eventQueue');
};



import {AxiosRequestConfig} from "axios";
import i18n from "../i18n";
import Cookies from 'js-cookie'
import {getDataStorage} from "../helpers/storage.helper";
import Auth, {UserIdentity} from "opensooq-sso";
import { useState } from "react";
import {generateToken} from "../helpers/auth.helper";
import {checkOnBehalfLogInFlow, utmCampaignParams} from "../helpers/functions";
 

export default async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const storage = getDataStorage()
    const device_uuid = storage.device_uuid || (storage.userInfo?.id || '')
    const lang = i18n.language.split("-", 2)[0]
    let token = '';
    if(await checkOnBehalfLogInFlow()){
        token =Cookies.get('EmployeeToken');
    }else{
        token = await Auth.getInstance().getToken(true,true);
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['source'] = storage.source
    config.headers['country'] = storage.country
    config.headers['release-version'] = storage.flowData.flow.releaseVersion
    config.headers['session-id'] = device_uuid
    config.headers['X-Tracking-UUID'] = device_uuid
    config.headers['accept-language'] = lang
    config.headers['enforce-language'] = lang
    config.headers['Authorization'] = "Bearer " + token
    // config.headers['Authorization'] = "Bearer " + await generateToken()

    if (sessionStorage.getItem('flow-type') === "requestFeature") {
        config.params = {
            ...config.params,
        }
    } else if (await checkOnBehalfLogInFlow()) {
        config.params = {
            ...config.params,
            expand: "remaining_edit_counter,media,post.overLimitType,post.isOverLimit",
            platform: "on-behalf"
        }
    } else {
        config.params = {
            ...config.params,
            expand: "remaining_edit_counter,media,post.overLimitType,post.isOverLimit",
            ...utmCampaignParams()
            
        }
    }

    return config
}
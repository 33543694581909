import React, {useContext, useState} from "react";
import {Props} from "./interface";
import {AxiosResponse} from "axios";
import styles from './Button.module.css'
import {useHttp} from "../../../../hooks";
import Modal from "../../../UI/Modal/Modal";
import {Button as MuiButton} from "@mui/material";
import PostContext from "../../../../storage/PostContext";
import {RefDataType} from "../../Tools/Button/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import {MediaMapper} from "../../../../helpers/media.functions";
import {configTakeActionApi} from "../../../../config/api.config";
import {getImgPath, getSizes, injectUtmsIntoWebviewUrl} from "../../../../helpers/functions";
import ConfirmationModel from "../../Tools/Button/ConfirmationModel";
import {getDataStorage} from "../../../../helpers/storage.helper";

const Button = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *             STATES
     * *********************************/

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isYes, setIsYes] = useState(false)
    const storage = getDataStorage()


    /************************************
     *             CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)


    /************************************
     *             HOOKS
     * *********************************/
    const takeActionHttp = useHttp<DynamicObject>(configTakeActionApi)


    /************************************
     *       API REQUEST HANDLER
     * *********************************/
    const requestTakeAction = takeActionHttp.request

    takeActionHttp.request = (args) => {
        const data = {
            ...postCtx.data.form,
            ...args?.config?.data,
        }
        const success = (response: AxiosResponse) => {

            const previewConfig: DynamicObject = postCtx.previewStep
            const arrOfCompo = response.data.reRender
            const listOfIdentifier = arrOfCompo.map((obj: { identifier: any; }) => obj.identifier);
            const lastPreview = previewConfig.childs.map((child: any) => {
                if (listOfIdentifier.includes(child.identifier)) {
                    const compo = arrOfCompo.find((compo: { identifier: any; }) => compo.identifier === child.identifier);
                    return compo
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }

            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...data
                },
                step: finalConfig,
                media: MediaMapper(response.data.media)
            })

        }
        requestTakeAction({
            ...args,
            config: {
                ...args?.config,
                workflow_id: postCtx.data.workflow_id,
                identifier: props.config.identifier,
                data: {
                    draftId: postCtx.data.draft_id,
                    entity_id: props.config.action?.data?.entity_id,
                    action_target: props.config.action?.data?.action_target,
                    action_type: props.config.action?.data?.action_type,
                }
            },
            callbacks: {
                ...args?.callbacks,
                success: success
            }
        })
    }

    if (isYes) {

        takeActionHttp.request()
    }

    /************************************
     *      INLINE BUTTON STYLE
     * *********************************/
    const buttonStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text.align,
        backgroundColor: props.config.background.color,
        borderColor: props.config.text.color,
    } as React.CSSProperties


    /*-------------------------------------
    *          FUNCTIONS
    * ----------------------------------*/

    /************************************
     *       MODAL HANDLER
     * *********************************/
    const onOpenHandler = () => {
        if (props.config.confirmation) {
            setIsOpen(true)
        } else {
            if (props.config.target.webviewURL){
                const webviewURLWithUTMS = injectUtmsIntoWebviewUrl(props.config.target.webviewURL);
                window.open(webviewURLWithUTMS);
            }else {
                const deeplink = props.config.target.deeplink.replace("xx", storage.country)
                window.open(`${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.","").replace("xx.","")}/site/dl?requestUri=${deeplink}`)
            }
        }
    }
    /************************************
     *       ACTION HANDLER
     * *********************************/
    const buttonActionHandler = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (value) {
            setIsYes(true)

        }
        setIsOpen(false);
    }

    return (
        <div style={{marginTop: "20px"}}>
            {
                isOpen && props.config?.confirmation &&
                <Modal title={props.config?.confirmation?.title} setIsOpen={setIsOpen}>
                    <ConfirmationModel config={props.config} buttonActionHandler={buttonActionHandler}/>
                </Modal>
            }
            <MuiButton variant='outlined' style={buttonStyle} fullWidth={true} endIcon={props.config.text?.icon &&
                <img className={styles.img_icon} src={getImgPath(props.config.text?.icon)}></img>}
                       onClick={(e: React.MouseEvent<HTMLElement>) => onOpenHandler()}
                       className={`${styles.button_body}`}>{props.config?.text.locale}</MuiButton>
        </div>
    )
})


export default Button
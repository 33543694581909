import {Props} from "./interface";
import styles from './FieldWithUnit.module.css'
import {getInputType, getSizes, toEnDigit} from "../../../../helpers/functions";
import {Select, SelectChangeEvent, TextField} from "@mui/material";
import DynamicObject from "../../../../models/dynamic-object";
import React, {ChangeEvent, CSSProperties, useEffect, useState} from "react";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {getDataStorage} from "../../../../helpers/storage.helper";

const FieldWithUnit = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config} = props

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<DynamicObject>({
        ref,
        config: props.config,
        autoSaveDelay: 3000,
        defaultValue: "",
        setConfig: props.setConfig,
    })

    const storage = getDataStorage()
    const [unit, setUnit] = useState<number | string>(state?.value ? state?.value['unit']?.toString() : parseInt(props?.config?.units && props?.config?.units[0]?.toString()))

    /************************************
     *   PREVENT DECREASING AND INCREASING
     *         IN SCROLLING
     * *********************************/
    document.addEventListener("wheel", function (event) {
        document.getElementById("outlined-number")?.blur()

    });


    const inputMinType = getInputType(props.config?.min?.format)
    const inputMaxType = getInputType(props.config?.max?.format)

    /************************************
     *       CLEAR VALIDATION
     * *********************************/
    useEffect(() => {
        validation.clear()
    }, [state.value ? state.value['min' || 'max'] : state.value])

    const manualClearValidation = () => {
        validation.clear()
    }
    /************************************
     *     SET INITIAL UNIT VALUE
     * *********************************/
    useEffect(function () {
        if (props.config?.units?.length > 0 && !unit && !state?.value?.unit && (state?.value?.min || state?.value?.max)) {
            setUnit(props.config.units[0].id)
            state.set({...state.value, unit: props.config.units[0].id})

        }
    }, [props.config?.units])


    useEffect(function () {
        if (!state.value)
            setUnit(props.config?.units[0]?.id)

    }, [])

    /********************************************
     *  ADD UNIT TO STATE WHEN USER START TYPING
     * *******************************************/
    useEffect(() => {
        if (state?.value?.min || state?.value?.max) {
            state.set({...state.value, unit: unit})
        }
    }, [state?.value?.min, state?.value?.max])
    /************************************
     *         UNIT HANDLER
     * *********************************/
    const addUnitHandler = (e: SelectChangeEvent<number | string>) => {
        manualClearValidation()
        setUnit(e.target.value)
        state.set({...state.value, unit: e.target.value})
    }

    const handleMaxKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.max?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else if (props.config?.max?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else if (props.config?.max?.format == 'all' || props.config?.max?.format == 'open') {
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else {
            event.preventDefault();
        }
    }

    const handleMinKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.min?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }

            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })

        } else if (props.config?.min?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })
        } else if (props.config?.min?.format == 'all' || props.config?.min?.format == 'open') {
            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })
        } else {
            event.preventDefault();
        }
    }

    /************************************
     *   MIN HANDLER + VALIDATION ON
     *             FORMAT
     * *********************************/
    const minUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.config?.min?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, min: toEnDigit(e.target.value)
                })
            }
        } else if (props.config?.min?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, min: toEnDigit(e.target.value)
                })
            }
        } else {
            state.set({
                ...state.value, min: toEnDigit(e.target.value)
            })
        }

    }


    /************************************
     *   MAX HANDLER + VALIDATION ON
     *             FORMAT
     * *********************************/
    const maxUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.config?.max?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, max: toEnDigit(e.target.value)
                })
            }
        } else if (props.config?.max?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, max: toEnDigit(e.target.value)
                })
            }
        } else {
            state.set({
                ...state.value, max: toEnDigit(e.target.value)
            })
        }
    }


    /************************************
     *   INLINE PLACEHOLDER MIN STYLE
     * *********************************/
    const minStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    /************************************
     *   INLINE PLACEHOLDER MAX STYLE
     * *********************************/
    const maxStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties


    /************************************
     *          MIN COMPONENT
     * *********************************/
    const min = <TextField className={`${styles.min}`} id="outlined-number"
                           placeholder={config.min?.placeholder.locale}
                           onChange={minUnitHandler}
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           InputLabelProps={{sx: minStyle}}
                           value={state.value ? state.value['min'] : ''}
                           inputMode={inputMinType}
                           onPaste={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCopy={(event) => {
                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={handleMinKey}
    />


    /************************************
     *          MAX COMPONENT
     * *********************************/
    const max = <TextField className={`${styles.max}`} id="outlined-number"
                           placeholder={config.max?.placeholder.locale}
                           onChange={maxUnitHandler}
                           InputLabelProps={{sx: maxStyle}}
                           value={state.value ? state.value['max'] : ''}
                           inputMode={inputMaxType}
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           onPaste={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }

                           }}
                           onCopy={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={handleMaxKey}


    />


    const units = config.units.map((unit, i) => {
        return <MenuItem value={unit.id}>
            <span> {unit.label}</span>
        </MenuItem>
    })


    const header = <div className={`mt-auto`}>
        <Select labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parseInt(unit?.toString())}
                IconComponent={ExpandMoreIcon}
                onChange={(e: SelectChangeEvent<number>) => addUnitHandler(e)}
                sx={{boxShadow: 'none', borderRadius: "8px"}}
                MenuProps={{sx: {borderBottom: '1px solid black'}}}

        >
            {units}
        </Select>
    </div>

    return (
        <>
            <div id={config.identifier}
                 className={`${storage.isMobile ? styles.mobileContainer : styles.container} `}>
                <div className={`${styles.body}`}>
                    {config.units.length > 1 ? header : config.units.length == 1 ? <div className={`${styles.header}`}>
                        <span> {props.config.units[0].label}</span>
                    </div> : ''}
                    {config.min && min}
                    {config.max && max}

                </div>
            </div>
            <ValidationError validation={validation}/>

        </>
    )
})

export default FieldWithUnit



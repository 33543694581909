import React, {useContext, useEffect, useMemo, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import * as flow from './Flow/index'
import DynamicObject from "../models/dynamic-object";
import {Args, UseHttpResponse} from "../hooks/use-http/interfaces";
import ComponentsRouting from '../config/components-routing.config'
import PostContext, {flowRefs} from "../storage/PostContext";
import {MediaMapper} from "../helpers/media.functions";
import {
    arrayIsEmpty, checkOnBehalfLogInFlow,
    getFinalPayload,
    getUrlBasedOnCountry, isIFrame,
    parentCallBack,
    removeMatchingKeys
} from "../helpers/functions";
import UIContext, {AFTER_LOADING_METHODS} from "../storage/UIContext";
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;
import MediasCacher from "../models/medias-cacher";
import LanguageContext from "../storage/LanguageContext";
import {generateAuthToken} from "../helpers/auth.helper";

import {PaymentWidget} from "opensooq-services";
import previous from "./UI/Previous/Previous";
import { logScreenLoad } from "../logging/helpers/commonLoggingFunctions";
import {getDataStorage} from "../helpers/storage.helper";
import Cookies from "js-cookie";

interface Config {
    [key: string]: any
}

interface Props {
    config: Config,
    nextApi: UseHttpResponse<DynamicObject>,
    Previous?: React.FunctionComponent,
    showMore?: boolean
}

interface RefType {
    getState: () => DynamicObject,
}

const Mapper = React.memo((props: Props) => {
    const {config, nextApi, Previous} = props
    const postCtx = useContext(PostContext)
    const storage = getDataStorage()
    const uiCtx = useContext(UIContext)
    // const showMore = props.showMore ?? true
    const showMore = false

    if (!config?.type)
        console.log(config, 'is heere mm')

    const typeName = config.type.capitalize();
    const identifier = config.type === "mediaStep" ? config.fieldIdentifier : config.identifier
    const attributes: DynamicObject = {}
    const Component = flow[typeName as keyof typeof flow]
    let childs = config.childs ?? false
    flowRefs[identifier] = React.createRef<RefType>()

    if (!Component) {
        throw new Error(`Component ${typeName} doesn't Exists`)
        return <></>
    }
    attributes.ref = flowRefs[identifier]

    {/*
        - CHECK IF THE COMPONENT WILL REDIRECT TO ANOTHER COMPONENT OR NOT ( FROM ROUTING CONFIG )
        - COMPONENT ROUTING DOESN'T HAS NEXT BUTTON
        - COMPONENT ROUTING WHEN ( ON CLICK ON COMPONENT , OR SELECT ELEMENT FROM MENU , ... )
        */
    }

    function loadData() {
        const data: DynamicObject = {}
        for (const key in flowRefs) {
            const ref = flowRefs[key]
            if (!ref?.current?.state?.get)
                continue

            const state = ref.current.state.get()
            const keys = key.match(/\b(\w+)\b/g) || []

            if (keys.length === 2) {

                let mainKey = keys[0] || ''
                if (!data[mainKey])
                    data[mainKey] = {}

                for (let i = 1; i < keys.length; i++) {
                    let name = keys[i]
                    data[mainKey][name] = state
                }
            } else {
                if (typeof state === "object") {
                    if (!data[key])
                        data[key] = {}

                    removeMatchingKeys(Object.keys(data[key]), state)
                    data[key] = {...data[key], ...state}
                } else {
                    data[key] = state

                }
            }
        }

        return data
    }

    if (ComponentsRouting[typeName as keyof typeof ComponentsRouting]) {
        {/*PREPARE NEXT API OR ( NEXT STEP )*/
        }
        attributes.nextApi = nextApi
        const request = nextApi.request
        nextApi.request = (args?: Args) => {
            const data: DynamicObject = loadData();
            // const data = loadData()
            postCtx.updateData({
                form: {...data}
            })

            const onError = (error: AxiosError) => {
                uiCtx.setLoading(false)
                if (error.response?.status === 422) {
                    const data = error.response?.data as DynamicObject
                    AFTER_LOADING_METHODS.push(() => {
                        console.log(data?.result?.errors)
                        for (const key in data?.result?.errors) {
                            const errorObject = data?.result?.errors[key] as DynamicObject
                            console.log(errorObject)

                            const ref = flowRefs[errorObject.field]

                            if (!ref?.current?.validation?.set)
                                continue

                            if (key == "0") {
                                let element = document.getElementById(errorObject.field)
                                element?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                            }

                            ref.current.validation.set(errorObject?.message)

                        }
                    })
                }
            }


            const onSuccessNextApi = (response: AxiosResponse) => {
                //logging
                logScreenLoad()
                //
                if (response.data.step.identifier.includes('CV') || response.data.step.identifier.includes('init_SubCategoryStepDraft')) {
                    parentCallBack('without_confirm_dialog')
                } else {
                    parentCallBack('with_confirm_dialog')
                }

                // request feature flow in normal step
                if (postCtx.data.flow_type == 'requestFeature') {
                    const url = getUrlBasedOnCountry(storage.country)
                    return window.top.location.href = url
                }

                const stepData = {
                    workflow_id: response.data.workflow_id,
                    draft_id: response.data.draft.id,
                    step: response.data.step,
                    start_time: Date.now(),
                    previous: response.data.previous,
                }

                if (response.data.draft.id !== postCtx.data.draft_id){
                    MediasCacher.getInstance().clear()
                }

                const mediaResult = MediaMapper(response.data.media)

                MediasCacher.getInstance().concat(mediaResult)

                const isCV = response.data.step?.identifier.includes('CV')

                const payload = isCV ? response.data.draft.payload : getFinalPayload(response.data.draft.payload)


                postCtx.updateData({
                    ...stepData,
                    form: {
                        ...data,
                        ...payload
                    },
                    media: mediaResult
                })


                uiCtx.setLoading(false)
                for (const key in flowRefs) {
                    if (flowRefs[key]?.current?.validation?.clear)
                        flowRefs[key].current.validation.clear()
                }
            }

            //to check if it's on behalf log in
            const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';


            request({
                ...args,
                config: {
                    ...args?.config,
                    data: {
                        ...data,
                        ...args?.config?.data,
                        member_id:isBehalfLogIn?storage?.userInfo?.id:'',
                    }
                },

                callbacks: {
                    ...args?.config?.callbacks,
                    error: onError,
                    success: onSuccessNextApi
                }
            })
        }
    }

    attributes.config = config
    attributes.loadData = loadData
    attributes.ref = flowRefs[identifier]


    useEffect(() => {


        // if (Object.values(uiCtx.errors)) {
        //     // const data = loadData()
        //     // postCtx.updateData({
        //     //     form: {...data}
        //     // })
        // }


    }, [uiCtx.errors])

    if (config.identifier.includes('DraftSection') && !arrayIsEmpty(childs) && showMore) {
        childs = [childs[0]]
        attributes.showMore = true
        attributes.otherchild = childs
        attributes.nextApi = props.nextApi
        attributes.Previous = props.Previous
    }

    // React.createElement(Component as React.FunctionComponent, {
    //     ...attributes,
    // }, childs && childs.map((child: Config) => {
    //     console.log('dfdfddfdfdfdfd', child, childs)
    //     return (<Mapper {...props} config={child}/>)
    // }))


    return <Component {...attributes as any}>
        {
            childs && childs.map((child: Config) => {
                return (<Mapper {...props} config={child}/>)
            })
        }
    </Component>
})


const MapperFlow = (props: Props): JSX.Element => {

    const [token, setToken] = useState<string>('')

    const getToken = async () => {
        if (await checkOnBehalfLogInFlow()) {
            return Cookies.get('EmployeeToken');
        } else {
            return generateAuthToken()
        }
    }
    useEffect(() => {
        getToken().then(token => {
            setToken(token ?? '');
        });
    }, [])

    return <><Mapper {...props} /></>

}

export default MapperFlow

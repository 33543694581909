import { AxiosResponse } from "axios";
import ArachnaApi from "./requests/arachna-api";
import DynamicObject from "../models/dynamic-object";
import { AddPostFilterResponse } from "../interceptors";

/* this is a singleton technique,
 so that only one request is made at a time,
and if a request is already in progress, it will return the same promise 'Momani'*/

let currentPromise: Promise<AxiosResponse> | null = null;

export default (config?: DynamicObject): Promise<AxiosResponse> => {
  if (!currentPromise) {
    const request = ArachnaApi.getInstance().request;
    request.interceptors.response.use(
      AddPostFilterResponse().success,
      AddPostFilterResponse().error
    );

    currentPromise = request
      .get(
        `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/update/${config?.post_id}`,
        {}
      )
      .finally(() => {
        currentPromise = null;
      });

    return currentPromise;
  } else {
    return currentPromise;
  }
};

import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import styles from "./TextArea.module.css"
import {getSizes} from "../../../../helpers/functions";
import {getDataStorage} from "../../../../helpers/storage.helper";
import languageContext from "../../../../storage/LanguageContext";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";


const TextArea = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    /*---------------------------------------------------------------
     *                            HOOKS
     * --------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {validation, state} = useDataPayload<string>({
        ref,
        config: props.config,
        autoSaveDelay: 3000,
        setConfig: props.setConfig,
        defaultValue: ""
    })
    /*---------------------------------------------------------------*/

    /************************************
     *              STATES
     * *********************************/

    const [count, setCount] = useState(state.value?.length ?? 0);
    const [row, setRows] = useState<number>(props.config.height)

    const storage = getDataStorage()
    const langCtx = useContext(languageContext)


    const fontSize: string = storage.isMobile ? "18px" : getSizes(props.config?.text?.style?.size)

    /************************************
     *       Text Area Style
     * *********************************/
    const textareaStyle = {
        '--padding': '16px 14px', // Set desired padding value
        '--border': validation.error ? '1px solid rgb(241, 79, 117)' : '1px solid #e6e3e3',
        '--borderRadius': '8px', // Set desired border radius
        "--text-font-size": fontSize, // Set desired font size
        "--text-font-weight": props.config.text?.style?.bold ? 'bold' : 'normal', // Set desired font weight
        "--text-font-color": props.config?.text?.color, // Set desired text color
        '--transition': 'border-color 0.3s', // Add transition for smooth color change
        '--outline': 'none', // Remove default focus outline
        "--placeholder-font-size": getSizes(props.config.placeholder?.style?.size), // Set desired font size
        "--placeholder-font-weight": props.config.placeholder?.style?.bold ? 'bold' : 'normal', // Set desired font weight
        "--placeholder-font-color": props.config.placeholder?.color,
    } as React.CSSProperties;


    /************************************
     *        ACTION HANDLER
     * *********************************/

    const handleChange = (event) => {
        state.set(event.target.value);
    }

    /************************************
     *       CLEAR VALIDATION
     * *********************************/
    useEffect(() => {
        setCount(state.value?.length || 0)
        if (state.value === props.values)
            return

        validation.clear()
    }, [state.value])

    return (
        <div>
            <TextareaAutosize
                id={props.config.identifier}
                className={`${styles.textArea}`}
                minRows={row}
                value={state.value}
                maxLength={props.config.limit} // Set the maximum length to 10
                onChange={handleChange}
                readOnly={!props.config.editable}
                style={textareaStyle}
                dir={langCtx.language == 'ar' ? 'rtl' : 'ltr'}
                placeholder={props.config.placeholder.locale}
            />
            {props.config.counter && <p style={{
                marginTop: "6px",
                opacity: "0.5",
                fontSize: "10px",
                display: "flex",
                justifyContent: "flex-end"
            }}>{langCtx.language == 'ar' ? `${props.config.limit} / ${count} ` : `${count} / ${props.config.limit}`}</p>}
            <ValidationError validation={validation}/>
        </div>
    );
})
export default TextArea

import React, {useContext, useEffect} from "react";
import stepModule from "./Step.module.css";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {default as StepModel} from "../../../models/steps/step";
import PostContext from "../../../storage/PostContext";
import DynamicObject from "../../../models/dynamic-object";
import {Args, UseHttpResponse} from "../../../hooks/use-http/interfaces";
import {AxiosResponse} from "axios";
import {useHttp, useQueryParams} from "../../../hooks";
import previousApi from "../../../api/previous.api";
import LanguageContext from "../../../storage/LanguageContext";
import {useTranslation} from "react-i18next";
import UIContext from "../../../storage/UIContext";
import {MediaMapper} from "../../../helpers/media.functions";

interface StepProps {
    children?: React.ReactNode;
    config?: StepModel,
    api?: UseHttpResponse<DynamicObject>,
    className?: string
}


const Step = (props: StepProps): JSX.Element => {
    // const {api} = props
    // const {t} = useTranslation();
    // const postCtx = useContext(PostContext)
    // const uiCtx = useContext(UIContext)
    // const navigate = useNavigate()
    // const previousHttp = useHttp<DynamicObject>({
    //     callback: previousApi,
    //     initialData: {}
    // })
    // const langCtx = useContext(LanguageContext)
    //
    // const currentStepData: DynamicObject = {}
    //
    // const request = previousHttp.request
    //
    // previousHttp.request = function (args?: Args) {
    //
    //     uiCtx.setLoading(true)
    //     const data: DynamicObject = {
    //         draftId: postCtx.data.draft_id,
    //         workflowId: postCtx.data.previous?.workflow_id,
    //         currentStep: postCtx?.data?.previous?.step_identifier,
    //     }
    //
    //
    //     const oldSuccess = args?.callbacks?.success
    //
    //     const onSuccess = (response: AxiosResponse) => {
    //         postCtx.updateData({
    //             workflow_id: response.data.workflow_id,
    //             draft_id: response.data.draft.id,
    //             step: response.data.step,
    //             start_time: Date.now(),
    //             previous: response.data.previous,
    //             media: MediaMapper(response.data.media),
    //         })
    //         uiCtx.setLoading(false)
    //     }
    //
    //     request({
    //         ...args,
    //         config: {
    //             ...args?.config,
    //             data: {
    //                 ...args?.config?.data,
    //                 ...data
    //             },
    //             lang: "en"
    //         },
    //         callbacks: {
    //             ...args?.callbacks,
    //             success: onSuccess
    //         },
    //
    //     })
    // }
    //
    // function callPreviousApi() {
    //     previousHttp.request(
    //         {
    //             config: {
    //                 data: {
    //                     draftId: postCtx.data.draft_id,
    //                     workflowId: postCtx.data.previous?.workflow_id,
    //                     currentStep: postCtx?.data?.previous?.step_identifier,
    //                 },
    //             },
    //         }
    //     )
    // }
    //
    // const onPreviousHandler = () => {
    //     callPreviousApi();
    // }
    //
    //
    //
    // //
    // window.onpopstate = function (event) {
    //     if (event && postCtx.data.previous) {
    //             callPreviousApi();
    //     }
    // }
    const {query} = useQueryParams();
    const postCtx = useContext(PostContext)
     
    useEffect(() => {
        //scroll to of the page in normal step
        const body = document.querySelector('#root');
        if(query?.get('flow') == 'my-cv' && postCtx.previewStep && postCtx.previewStep['type'] == "previewStep" ) return
        body.scrollIntoView({
            behavior: 'smooth'
        })

    }, [])


    return (
        <>
            {/*{*/}
            {/*    postCtx?.data?.previous &&*/}
            {/*    <div className={`${stepModule["previous"]} `} >*/}
            {/*        {(langCtx.language == 'en') ? <FaAngleLeft onClick={onPreviousHandler} className={stepModule.icon}/> :*/}
            {/*            <FaAngleRight onClick={onPreviousHandler} className={stepModule.icon}/>}*/}
            {/*        <div className={`${stepModule.text} cursor__pointer`} onClick={onPreviousHandler}>  {t('previous')}</div>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className={`${stepModule.container}${props.className ? ` ${props.className}` : ''}`}>
                {props.children}
            </div>
        </>
    )
}

export default Step
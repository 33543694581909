import React, {useEffect} from "react";
import {Props} from './interfaces';
import styles from "./TextField.module.css"
import {TextField as TextFieldMUI} from "@mui/material";
import {getFieldType, getInputType, getSizes, toEnDigit} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {getDataStorage} from "../../../../helpers/storage.helper";

const TextField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config,
        autoSaveDelay: 3000
    })

    const storage = getDataStorage()

    const type = getFieldType(props.config.format.toLowerCase())
    const inputType = getInputType(props.config.format.toLowerCase())


    const textHandler = (e) => {
        if (props.config.format.toLowerCase() == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                state.set(toEnDigit(e.target.value));
            }
        } else if (props.config.format.toLowerCase() == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                state.set(toEnDigit(e.target.value));
            }
        } else {
            state.set(toEnDigit(e.target.value));
        }
    }

    useEffect(() => {
        validation.clear()
    }, [state.value])

    const attributes = {
        id: "outlined",
        fullWidth: true,
        maxRows: 4,
        placeholder: props.config.placeholder.locale,
        InputLabelProps: {
            style: {
                color: props.config.placeholder.color,
                fontWeight: props.config.placeholder.style?.bold ? 'bold' : 'normal',
                fontSize: getSizes(props.config.placeholder.style?.size),
            }
        },
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth <= 768 ? '18px' : getSizes(props.config.text?.style?.size),
            },
            readOnly: !props.config.editable,
        }

    }

    const handleKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config.format.toLowerCase() == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            state.set(toEnDigit(event.target.value));
        } else if (props.config.format.toLowerCase() == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            state.set(toEnDigit(event.target.value));
        } else if (props.config.format.toLowerCase() == 'all' || props.config.format.toLowerCase() == 'open' || props.config.format.toLowerCase() =='alphanum') {
            state.set(toEnDigit(event.target.value));
        } else {
            event.preventDefault();
        }
    }


    document.addEventListener("wheel", function (event) {
        if (type === "number")
            document.getElementById("outlined")?.blur()

    });


    return (

        <div id={props.config.identifier}  className={`${styles.container} `}>
                <TextFieldMUI {...attributes}
                              autoComplete={"off"}
                              sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                              onChange={textHandler}
                              value={state.value}
                              className={`${styles.text_locale_ar}`}
                              variant={'outlined'}
                              inputMode={inputType}
                              error={!!validation.error}
                              onPaste={(event) => {
                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              onCopy={(event) => {
                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              onCut={(event) => {

                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              autoCorrect={"off"}
                              spellCheck={"false"}
                              onKeyPress={handleKey}

                />

            <ValidationError validation={validation}/>

        </div>

    )
})

export default TextField
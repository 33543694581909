import axios, {AxiosResponse} from "axios";
import ArachnaApi from './requests/arachna-api'
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import {getDataStorage} from "../helpers/storage.helper";
import i18n from "../i18n";
import {generateAuthToken} from "../helpers/auth.helper";
import {checkOnBehalfLogInFlow} from "../helpers/functions";
import Cookies from "js-cookie";

export default async (config?: DynamicObject): Promise<AxiosResponse> => {
    const storage = getDataStorage()
    // const flow = config?.type === 'cv' ? 'cv' : 'add-post'

    //PUT /vertical/forms/v1/<type>/<source>/<stepIdentifier>/drafts/<draftId>

    const device_uuid = storage.device_uuid || (storage.userInfo?.id || '')

    let platform = '';
    let token = '';
    if (await checkOnBehalfLogInFlow()) {
        token =Cookies.get('EmployeeToken');
        platform="on-behalf"
    }else {
        token = await generateAuthToken();
    }
    return axios.post(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/save-data/${config?.workflow_id}/${config?.identifier}${platform?'?platform='+platform:''}`, {
        ...config.data,
        stepIdentifier: config.stepIdentifier,
        draftId: config.draftId
    }, {
        headers: {
            source: storage.source,
            country: storage.country,
            [`enforce-language`]: i18n.language,
            [`session-id`]: device_uuid,
            [`X-Tracking-UUID`]:String(device_uuid),
            ['Authorization']: "Bearer " + token,
            ['release-version']: storage.flowData.flow.releaseVersion
        }
    })
}

import {useCallback, useEffect} from "react";
import DynamicObject from "../../models/dynamic-object";
import {flowComponents, flowLevels, flowRefs} from "../../storage/PostContext";
import FlowReferences from "../../flow-references";

interface Props {
    config: DynamicObject,
    state: any,
    defaultState: any,
}

const useRelated = ({config, state}: Props) => {
    // const


    const reRenderComponentHandler = useCallback((identifier, config) => {

        FlowReferences.get(identifier).config.set(config)
        FlowReferences.reRenderedFlow[identifier] = 'yes'

        // flowRefs[config.identifier].state.set("")
        //
        // flowRefs[config.identifier].config.set({
        //     ...flowRefs[config.identifier].config.get(),
        //     values: []
        // })

    }, [config, state, flowRefs])



   return {
       reRenderComponentHandler
   }
}
export default useRelated
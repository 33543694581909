const DEFAULT_VALUE = '.'

export default class GridLayout {
    gridCols: string[]


    private constructor() {
        this.gridCols = []
    }

    static createInstance() {
        return new this()
    }

    private getGridSize = () => this.gridCols.length

    draw() {
        let grid = ''

        for (const col of this.gridCols)
            grid += `${col} `

        return grid.trim()
    }

    getGridColumns = () => {
        const size = 100 / this.getGridSize()-1.5
        return ` ${size}% `.toString().repeat(this.getGridSize()).trim()
    }

    getMax(cols,max) {
        const maxCols = Math.max(...cols)
        let numberOfCols = this.getGridSize()

        let loopCount = numberOfCols > maxCols ? numberOfCols : maxCols

        if (cols.length == 1 && cols.length <maxCols)
            loopCount =max
        
        return loopCount
    }

    load(identifier: string, cols: number[], max = null) {

        const maxCols = Math.max(...cols)
        let numberOfCols = this.getGridSize()
        const loopCount = this.getMax(cols,max)


        for (let i = 0; i < loopCount; i++) {
            if (cols.includes(i + 1)) {
                this.gridCols[i] = identifier
            } else if (this.gridCols[i] !== DEFAULT_VALUE && this.gridCols[i] !== undefined) { // if has a value
                continue
            } else {
                this.gridCols[i] = DEFAULT_VALUE
            }
        }
    }

}
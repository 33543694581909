
export enum EnumUxTypes {
  LINK = "link",
  BUTTON = "button",
  BUTTON_FLOATING = "button_floating",
  BUTTON_BOTTOM_BAR = "button_bottombar",
  BUTTON_TOP_BAR = "button_topbar",
  BUTTON_TOGGLE = "button_toggle",
  ICON = "icon",
  ICON_MENU_ITEM = "icon_menu_item",
  IMAGE = "image",
  LIST_CELL = "list_cell",
  LIST = "list",
  SWIPE = "swipe",
  SCROLL = "scroll",
  TAB = "tab",
  SLIDER = "slider",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  VIDEO = "video",
  SCREEN = "screen",
  MODAL = "modal",
  RATING_ADD = "ratings_add",
  NA="NA"
}

export interface ActionName {
  L1 : EnumActionNameLevel1
  L2 : EnumActionNameLevel2
  L3 : EnumActionNameLevel3
}

export interface Post {
  id:number, country? : string, 
  neighborhoodName? : string, 
  city? : {name? : string, lat? : number, lng?: number}, 
  category?: {label:string}, 
  subCategory?: string, 
  service : string, 
}

export interface Member {
  id : number
}

export enum EnumWidgets {
  AD_UNTIL = "ad_unit",
  ADD_LISTING = "add_listing",
  ADD_LISTING_SIMILAR = "add_listing_similar",
  BOTTOM_STICKY = "bottom_sticky",
  CATEGORIES_LIST_ICON = "categories_list_icons",
  CONTACT_US = "contact_US",
  EXPORT_LISTING_OPTIONS = "export_listing_options",
  FILTERS = "filters",
  FILTERS_FACETS = "filters_facets",
  GENERAL_TIPS = "general_tips",
  HEADER = "header",
  HEADER_STICKY = "header_sticky",
  REELS_LIST = "reels_list",
  LISTINGS_CONTINUE_BROWSING = "listings_continue_browsing",
  LISTING_RECENTLY_VIEWED = "listings_recently_viewed",
  LISTING_LATEST_BY_CATEGORY = "listings_latest_by_category",
  LISTING_LOCATION_MAP = "listing_location_and_map",
  LISTING_INFORMATION = "listing_information",
  LISTING_DESCRIPTION = "listing_description",
  LISTING_IMAGE = "listing_image",
  LISTING_TITLE = "listing_title",
  LISTING_PRICE = "listing_price",
  LISTING_CELL = "listing_cell",
  LISTING_LEADS = "listing_leads",
  LISTER_OVERVIEW = "lister_overview",
  LISTER_MEMBER_OPENSOOQ_LINK = "lister_member_opensooq_link",
  LISTING_SIMILAR = "listings_similar",
  LISTING_RECOMMENDED = "listings_recommended",
  LISTING_QUALITY_SCORE = "listing_quality_score",
  LISTING_STATISTICS = "listing_statistics",
  LISTING_DETAILS = "listing_details",
  NAVIGATION_BOTTOM_BAR = "navigation_bottom_bar",
  RESULTS_HEADER = "results_header",
  RATING_ADD = "ratings_add",
  RATING_SUMMARY = "ratings_summary",
  RATING_BADGES = "ratings_badges",
  RATING_REVIEWS = "ratings_reviews",
  PROMOTE_LISTING = "promote_listing",
  SPOTLIGHT_BOTTUN = "spotlight_button",
  SHARE_LISTING_OPTIONS = "share_listing_options",
  SEARCH_BAR = "search_bar",
  SLIDERS = "sliders",
  TABS = "tabs",
  WALLET_CELL = "wallet_cell",
  BOTTOM_BAR = "bottom_bar",
  NA="NA"
}


export enum EnumActionNameLevel1 {
  CALL = "call",
  APPLY = "apply",
  ADD = "add",
  VIEW = "view",
  ORDER = "order",
  FAVORITE = "favorite",
  MESSAGE = "message",
  AUTHENTICATE = "authenticate",
  FOLLOW = "follow",
  SEARCHES_SAVE = "searches_save",
  SHARE = "share",
  REPORT = "report",
  RATING = "rating",
  SEARCH = "search",
  SCREEN = "screen",
  EDIT = 'edit',
  PERMISSION = "permission",
  LIMIT= "limit",
  NA = "NA",
}
export enum EnumActionNameLevel2 {
  LOAN = "loan",
  LISTING = "listing",
  CV = "cv",
  MEMBER = "member",
  LISTING_ADD = "listing_add",
  LISTING_REMOVE = "listing_remove",
  ADD = "add",
  REMOVE = "remove",
  SEARCH = "search",
  APP = "app",
  KEYWORD = "keyword",
  TAXONOMY = "taxonomy",
  FILTERS = "filters",
  SIMILAR = "similar",
  LOAD = "load",
  START = "start",
  PRODUCTS = "products",
  SELLER = "seller",
  BUYER = "buyer",
  LOCATION = "location",
  NA = "NA",
}




export enum EnumActionNameLevel3 {
  CLICK = "click",
  SUCCESS = "success",
  VIEW = "view",
  SUBMIT = "submit",
  SEND = "send",
  ALLOW = "allow",
  DENY = "deny",
  CATEGORY = "category",
  SUB_CATEGORY="subcategory",
  ACCOUNT="account",
  NA = "NA",
}

export const LoggingScreenNames : {[key: string]: string} = {
  ""  : "home",
  "/" : "home",
  "chats" : "chat_center",
  "notifications" :"notifications_timeline",
  "listing-view":"my_listing_view",
  "my-listings" : "my_listings",
  "draft-listings":"NA",
  "favorite-listings" : "favorites_listings",
  "lsting_view" : "listing_view",
  "recently-viewed" : "NA",
  "following-listings" : "follow_listings",
  "job-applications" : "NA",
  "saved-search" : "NA",
  "recent-search" : "NA",
  "my-account":"my_account",
  "my-rating" : "my_ratings",
  "my-wallet":"my_wallet",
  "followers" : "my_follows",
  "following" : "my_follows",
  "settings" :"settings",
  "products" : "products",
  "invite-friends" : "invite_friends",
}

export enum EnumCellType {
  TEXT_ONLY = "textOnly",
  CLASSIC = "classic",
  IMAGE="image"
}

export enum EnumGeneratedBy {
  USER = "user",
  SYSTEM = "system",
  TIME = "time",
  USER_SHORTCUT = "user_shortcut"
}
export interface ILoggingAPIBody{
  Geo_user_country: string,
  Geo_user_city: string,
  Geo_user_location: string, 
  Geo_app_country: string,
  Geo_post_country: string,
  Geo_post_city: string,
  Geo_post_neighborhood: string,
  Geo_post_location: string,

  Tech_device_brand: string,
  Tech_device_model: string,
  Tech_device_screen_resolution: string,
  Tech_device_os: string,
  Tech_device_os_version: string,
  Tech_device_browser: string,
  Tech_device_browser_version: string | number,
  Tech_device_language: string,
  Tech_device_timezone: string,
  Tech_device_user_agent: string,
  Tech_device_cpu_arch: string,
  Tech_http_referrer: string,
  Tech_app_version: string | number,
  Tech_app_language: string,
  Tech_app_platform:string,
  Tech_app_ui_theme: string,
  Tech_connection_type: string,
  Tech_connection_latency: string,

  User_abbucket?: string | number,
  User_member_id?: string | number,
  User_tracking_id?: string | number,
  User_type?: string,
  User_authentication?: string,
  User_target_id?: string | number,
  User_target_type?: string,

  Session_id: string | number,
  Session_search_id: string | number,
  Session_source: string,
  Session_medium: string,
  Session_campaign: string,
  Session_life:  string,
  
  Action_screen_name: string,
  Action_screen_name_previous: string,
  Action_widget_name: string ,
  Action_ux_type: string,
  Action_name: string,
  Action_name_lvl2: string,
  Action_name_lvl3: string,
  Action_widget_name_previous: string,
  Action_name_previous: string,
  Action_name_lvl2_previous: string,
  Action_name_lvl3_previous: string,
  Action_listing_id: string | number,
  Action_listing_status: string,
  Action_listing_category: string,
  Action_listing_subcategory: string,
  Action_listing_paid_features: string,
  Action_listing_cell_type: string,
  Action_listing_origin_slot_type: string,
  Action_reference_id: string | number,
  Action_reference_entity: string,
  Action_generated_by: string,

  filter_category : 'YES' | 'NO' | 'NA',
  filter_subcategory : 'YES' | 'NO' | 'NA',
  filter_listing : 'YES' | 'NO' | 'NA',
  filter_member : 'YES' | 'NO' | 'NA',
  filter_keyword : 'YES' | 'NO' | 'NA',
  filter_cp : 'YES' | 'NO' | 'NA' ,

  Session_funnel_source: string,
  Session_funnel_point_of_entry: string
}
export interface IUtms {
  utmMedium?: string;
  utmCampaign?: string
  utmSource?: string

}


export interface ILoggingAPIsuccessResposnse {
  "status": true
}
//helpers types
export interface INetworkConnection{
  effectiveType:string,
  rtt:string
}

export interface IGetInformationFromCookies {
  source: string;
  sessionId: string;
  trackingId: string;
  // userInfo: LiteUserInfo;
  postCountry:string;
  sessionLife:number
}

export enum EnumReferenceEntity {
  MEMBER = "member",
  POST = "post",
  ORDER = "order",
  SAVED_SEARCH = "saved_searches",
}


export const HTML_ELEMENTS_CLASSES = [
  "searchFacetsSource",
  "viewMoreBtnSource",
  "homeFacetsSource",
  "megaMenuSource",
  "moreCategorySource",
  "postListItemSource"
] as const;

export type TypeElementsClasses = typeof HTML_ELEMENTS_CLASSES[number];

export type TypeLoggingSelector = TypeElementsClasses ;

export interface Location {
  latitude : string
  longitude : string
}

export const DEFAULT_VALUE = 'NA'

export const DEFAULT_VALUE_COUNTRY = 'xx';

export const EMPTY_LOCATION: Location = {
  latitude : DEFAULT_VALUE,
  longitude : DEFAULT_VALUE
}
export const EMPTY_ACTION_NAME = {
  L1 : EnumActionNameLevel1.NA,
  L2 : EnumActionNameLevel2.NA,
  L3 : EnumActionNameLevel3.NA
}

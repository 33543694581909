import React from "react";
import styles from './ContentContainer.module.css'

interface ContentContainerProps {
    children?: React.ReactNode;
}

const ContentContainer = (props: ContentContainerProps): JSX.Element => {
    return (
        <div id={'content-container'} className={styles["content-container"]}>
            {props.children}
        </div>
    )
}

export default ContentContainer
import React, {useContext} from "react";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

import CityFlowFieldMobile from "./CityFlowFieldMobile/CityFlowFieldMobile";
import CityFlowFieldDesktop from "./CityFlowFieldDesktop/CityFlowFieldDesktop";
import {getDataStorage} from "../../../../helpers/storage.helper";
import DynamicObject from "../../../../models/dynamic-object";
import FlowReferences from "../../../../flow-references";
import NotAutoSaveState from "../../../../not-auto-save-state";
import {Props} from "./interfaces";


const CityFlowField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config, setConfig} = props


    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const storage = getDataStorage()

    const {state, validation} = useDataPayload<number | string>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        defaultValue: ""
    })
    const rest = () => {
        if (!config.linked?.length) {
            return
        }
        config.linked.map(identifier => {
            NotAutoSaveState.set(identifier)
            FlowReferences.get(identifier).state.clear()
        })
    }
    state.set = (value) => {
        state.set(value, () => {
            rest()
        })
    }
    const onChangeValues = (id, values) => {
        if (props.onChangeValues)
            props.onChangeValues(id, values, () => rest())
    }

    return (
        <>
            {storage.isMobile ? <CityFlowFieldMobile selectFieldLevels={props.selectFieldLevels} config={props.config}
                                                     setConfigData={props.setConfigData}
                                                     selectFieldConfig={props.selectFieldConfig} nextApi={props.nextApi}
                                                     tempFormData={props.tempFormData} ref={ref}


                /> :
                <CityFlowFieldDesktop state={state}
                                      validation={validation}
                                      setConfig={setConfig}
                                      selectFieldLevels={props.selectFieldLevels} config={props.config}
                                      setConfigData={props.setConfigData} selectFieldConfig={props.selectFieldConfig}
                                      nextApi={props.nextApi} tempFormData={props.tempFormData} {...props}
                                      onChangeValues={onChangeValues}
                />}

        </>
    )
})

export default CityFlowField
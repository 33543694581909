import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UiContextProvider} from "./storage/UIContext";
import {PostContextProvider} from "./storage/PostContext";
import {LanguageContextProvider} from "./storage/LanguageContext";
import {BrowserRouter} from "react-router-dom";
import Cookies from 'js-cookie';
import Auth, {UserIdentity} from "opensooq-sso";
import {checkOnBehalfLogInFlow, hasNoPassword, isAuthLoggedOut, isIFrame} from './helpers/functions';
import { getDataStorage } from './helpers/storage.helper';
import { ToastContainer } from 'react-toastify'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const getOriginDomain = () => {
    var hostname = window.location.hostname; 
    var parts = hostname.split('.');
    if (parts.length > 2) {
      hostname = parts.slice(1).join('.');
    }
    return hostname; 
}


const checkEcountry = async () => {
    if(!Cookies.get('ecountry')){
        window.location.href = process.env.REACT_APP_JO_SOOQ_URL.replace("XX","www");
    }
}

const initAuth = async () => {
    //initAuth With Behalf Log In
    if (await checkOnBehalfLogInFlow()) {
        return true;
    }else {
        await checkEcountry();
        const newAuth = Auth.getInstance();
        const loggedAuth = await newAuth.init(
            getOriginDomain(),
            "sso",
            Cookies.get('NEXT_LOCALE') ?? 'ar',
            Cookies.get('ecountry') || 'jo',
            Cookies.get('source') || 'desktop',
            Cookies.get('device_uuid') || '',
            Cookies.get('cityId') || null,
        )

        if(isAuthLoggedOut(loggedAuth.state) || hasNoPassword()){
            await Auth.getInstance().authenticate(encodeURIComponent(window.location.href) , true);
        }
    }
};

initAuth().then(() => {
    root.render(
        // <React.StrictMode>
        <>
            <BrowserRouter>
                <UiContextProvider>
                    <LanguageContextProvider>
                        <PostContextProvider>
                            <App/>
                            {!!!Cookies.get('EmployeeToken') && !isIFrame() &&
                                <ToastContainer style={{width: Cookies.get('source') === 'mobile' ? '100%' : '1200px'}}
                                                autoClose={false} draggableDirection="y" draggablePercent={60}/>
                            }
                        </PostContextProvider>
                    </LanguageContextProvider>
                </UiContextProvider>
            </BrowserRouter>
        </>
        // </React.StrictMode>
    );
}).catch((e) => {
    console.error('Init error inside index',e);
})
reportWebVitals();



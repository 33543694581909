import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import MediasCacher from "../models/medias-cacher";
import {checkOnBehalfLogInFlow, isIFrame} from "../helpers/functions";
import {Session} from "inspector";
import Cookies from "js-cookie";

const initFormData = (data) => {
    const formData = new FormData()

    function extractToFormData(data, group = null) {
        for (let key in data) {
            let value = data[key]

            if (group){
                key = `${group}[${key}]`
            }

            if ([null, undefined].includes(value)) {
                formData.append(`${key}`, '')
                continue;
            }

            if (typeof value === 'object') {
                extractToFormData(value, key)
                continue;
            }


            formData.append(`${key}`, value)
        }
    }
    extractToFormData(data);
    return formData
}

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const urlParams = new URLSearchParams(window.location.search)

    console.log(config,"here555")
    const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';
    const publish = urlParams.get('flow') === "edit" ? "update" : isBehalfLogIn ?'on-behalf':'normal'
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)
    if (urlParams.get('flow') === "jobApply") {
        var url = `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/job-apply/finalize/${urlParams.get('post_id')}`
    } else if (config?.flow_type === "survey") {
        var url = config?.component_name ? `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/survey/${publish}/finalize/${config?.workflowId}/${config?.component_name}` : `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/survey/${publish}/finalize/${config?.workflowId}`
    
    } else if (config?.flow_type === "reviews") {
        var url = config?.component_name ? `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/review/${publish}/finalize/${config?.formType}/${config?.workflowId}/${config?.component_name}` : `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/review/${publish}/finalize/${config?.formType}/${config?.workflowId}`
    } else if (sessionStorage.getItem('flow-type') === "requestFeature") {
        var url =`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/requestFeature/${publish}/finalize/${config?.workflowId}`
    } else
        var url = config?.component_name ? `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/${publish}/finalize/${config?.workflowId}/${config?.component_name}` : `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/${publish}/finalize/${config?.workflowId}`

    const medias = {}
    for (const mediaIdentifier in MediasCacher.getInstance().all()) {
        medias[mediaIdentifier] = Object.values(MediasCacher.getInstance().get(mediaIdentifier)?.medias || []).length
    }

    const data = {
        ...config?.data,
        ...medias
    }


    const formData = initFormData(data)

    return request.post(`${url}`, formData)
}
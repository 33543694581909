import { DEFAULT_VALUE } from "../types/LoggingTypes";

export class UserDimensions {
    private static instance : UserDimensions;
    private _userTrackingId : string = DEFAULT_VALUE;
    private _userType: string = DEFAULT_VALUE;//missing
    private _userTargetId:string = DEFAULT_VALUE;
    private _userTargetType:string = DEFAULT_VALUE; //missing
    private _userMemberId:string = DEFAULT_VALUE;

    public static getInstance(){
        if(!UserDimensions.instance)
        {
            UserDimensions.instance = new UserDimensions();
        }
        return UserDimensions.instance;
    }

    get userAuth():string {
        return this._userMemberId === DEFAULT_VALUE ? 'LoggedOut' : 'LoggedIn';
    }

    get userMemberId():string {
        return this._userMemberId;
    }

    set userMemberId(_userMemberId:string)
    {
        this._userMemberId = _userMemberId;
    }

    get userTargetId():string {
        return this._userTargetId;
    }

    set userTargetId(_userTargetId:string)
    {
        this._userTargetId = _userTargetId;
    }
    
    get userTargetType():string {
        return this._userTargetType;
    }

    get userAbbucket():string {
        return DEFAULT_VALUE;
    }

    get userType():string {
        return this._userType;
    }

    get userTrackingId():string {
        return this._userTrackingId;
    }

    set userTrackingId(_userTrackingId:string)
    {
        this._userTrackingId = _userTrackingId;
    }

}
import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import {AxiosResponse} from "axios";
import styles from './Score.module.css'
import createCache from '@emotion/cache';
import {ThemeProvider as StyledThemeProvider} from '@mui/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import {useHttp} from "../../../../hooks";
import {CacheProvider} from '@emotion/react';
import ScoreApi from "../../../../api/score.api";
import {Slider, Typography, ThemeProvider, createTheme} from "@mui/material";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import languageContext from "../../../../storage/LanguageContext";

const Score = (props: Props) => {


    /************************************
     *            CONTEXTS
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    console.log(langCtx.language, "here3")

    /************************************
     *            STATES
     * *********************************/
    const [value, setValue] = useState(postCtx.data.form['score'])

    /************************************
     *           CONSTANTS
     * *********************************/
    const theme = createTheme({
        direction: (langCtx.language == 'ar') ? 'rtl' : 'ltr', // Both here and <body dir="rtl">
    });

    const createCacheData = {
        key: 'muirtl',
        stylisPlugins: (langCtx.language == 'ar') ? [rtlPlugin] : []
    }

    const cacheRtl = createCache(createCacheData);

    /*-------------------------------------
    *            FUNCTIONS
    * ----------------------------------*/

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    /*---------------------------------------------------------------*/

    /************************************
     *         CSS STYLE
     * *********************************/
    const containerStyle = {
        "--title-font-weight": props.config.title.style?.bold ? "bold" : "normal",
        "--title-font-size": getSizes(props.config.title.style?.size) + "px",
        "--title-font-color": props.config.title.color
    } as React.CSSProperties


    /************************************
     *      PREPARE SCORE API
     * *********************************/
    const configGetScoreApi = {
        callback: ScoreApi,
        initialData: {},
        withLoader: true
    }
    const scoreHttp = useHttp<DynamicObject>(configGetScoreApi)

    const requestSaveScore = scoreHttp.request

    scoreHttp.request = (args) => {

        const onSuccessScoreApi = (response: AxiosResponse) => {
            postCtx.addToForm('score', response.data.score)
            setValue(value)
        }

        requestSaveScore({
            callbacks: {
                ...args?.callbacks,
                success: onSuccessScoreApi
            }
        })
    }


    useEffect(() => {
        if (!value) {
            scoreHttp.request()
        }
    }, [])

    return (
        <CacheProvider value={cacheRtl ?? undefined}>
            <ThemeProvider theme={theme ?? undefined}>
                <StyledThemeProvider theme={theme ?? undefined}>
                    <div className={`${styles.container}`} style={containerStyle}>
                        <div className={`d-flex justify-content-between ${styles.header}`}>
                            <Typography id="non-linear-slider" gutterBottom
                                        className={'label'}>{props.config.title.locale}</Typography>
                            <Typography id="non-linear-slider" gutterBottom>{value}%</Typography>
                        </div>
                        <Slider
                            // disabled={!props.config.progress.enabled}
                            value={value}
                            min={1}
                            step={10}
                            max={100}
                            disabled={true}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            className={`${styles.muiGreenOverwrite} ${styles.slider} `}
                            aria-labelledby="non-linear-slider"
                            sx={{
                                color: '#44bd0f',
                                '& .MuiSlider-thumb': {
                                    border: "1px solid #00b9d2",
                                    backgroundColor: "#fff"
                                },
                                // '& .Mui-disabled':{
                                //     color: '#44bd0f'
                                // }
                            }}
                            marks
                        />

                        <ul className={`${styles.items}`}>
                            {
                                props.config.bullets.map(item => {
                                    return (<li>{item}</li>)
                                })
                            }
                        </ul>
                    </div>
                </StyledThemeProvider>
            </ThemeProvider>
        </CacheProvider>

    )
}

export default Score
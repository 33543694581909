import React, {useContext} from "react";
import DynamicObject from "../../../models/dynamic-object";
import {UseHttpResponse} from "../../../hooks/use-http/interfaces";
import {Button as MuiButton} from "@mui/material";
import {getSizes} from "../../../helpers/functions";
import UIContext from "../../../storage/UIContext";
import styles from "./NextButton.module.css"
import CircularProgress from "@mui/material/CircularProgress";
import {getDataStorage} from "../../../helpers/storage.helper";

interface NextButtonProps {
    nextApi?: UseHttpResponse<DynamicObject>;
    style?: {
        bold?: boolean,
        size?: string,
        color?: string,
        backgroundColor?: string
        borderRadius?: string
        marginTop?: string
        width?: string
    },
    children?: React.ReactNode;
    className?: string
    onClick?: Function
    isSticky: boolean
}


const NextButton = (props: NextButtonProps): JSX.Element => {
    const {nextApi, style, children} = props

    const uiCtx = useContext(UIContext)
    const storage = getDataStorage()

    const submitStyle = {
        fontWeight: style?.bold ? "bold" : "normal",
        fontSize: getSizes(style?.size || "m"),
        width: style.width,
        borderRadius: style?.borderRadius ? style.borderRadius : "8px",
        color: style?.color,
        backgroundColor: style?.backgroundColor,
        margin: props.isSticky ? "5px" : "0px",
        padding: "10px"

    } as React.CSSProperties


    const nextHandler = () => {
        if (props.onClick) {
            return props.onClick(nextApi)
        }

        const httpConfig: DynamicObject = {}
        nextApi?.request({})
    }

    return (
        <div
            className={`${props.isSticky ? storage.isMobile ? styles.button_container : styles.button_container_in_desktop : ''}`}
            style={{width: "100%",padding:storage.isMobile?'0px':'5px 0px'}}>
            <div className={`${storage.isMobile ? `d-flex justify-content-center align-items-center w-100 ${styles.background_white}`:styles.button_subcontainer_in_desktop}`}>
                <MuiButton disabled={uiCtx.btnLoading && true} id={'next-btn'} variant='contained' style={submitStyle}
                           className={`d-block`}
                           onClick={(e: React.MouseEvent<HTMLElement>) => nextHandler()}>{children}

                </MuiButton>
            </div>


        </div>

    )
}

export default NextButton
import DynamicObject from "../models/dynamic-object";
import {NEW_FLOW, OLD_FLOW} from "../constants/flow";
import {getDataStorage} from "./storage.helper";
import Cookies from "js-cookie";

const STEP_TYPES: string[] = [
    "normalStep",
    "mediaStep",
    "previewStep",
    "ifStep",
]


type TypeOfSize = {
    [key: string]: number;
};

const FONT_SIZES: TypeOfSize = {
    xs: 14,
    s: 16,
    m: 18,
    l: 20,
    xl: 24,
}
const MOBILE_FONT_SIZES: TypeOfSize = {
    xs: 8,
    s: 14,
    m: 17,
    l: 20,
    xl: 25,
}

export const mustache = (string: string, data: Object) => {
    return Object.entries(data).reduce((res, [key, value]) => {
        const search = `{${key}}`

        if (
            res.indexOf(search) !== -1 &&
            res.indexOf(`\\${key}`) == -1 &&
            res.indexOf(`${key}\\`) == -1
        ) {
            return res.replace(search, value)
        }

        return res
    }, string)
}


export const isStep = (type: string): boolean => {
    return STEP_TYPES.includes(type)
}

export const getSizes = (size?: string | undefined): string => {
    const storage = getDataStorage()

    size = FONT_SIZES[size || "m"] ? size : "m";
    return FONT_SIZES[size || "m"] + 'px';

}

export const getImgPath = (img: string): string => {
    const replaceArray = ['platform', 'size'];
    const replaceWith = ['android', 'xxh'];

    for (let i = 0; i < replaceArray.length; i++) {
        img = img?.replace(new RegExp('{' + replaceArray[i] + '}', 'gi'), replaceWith[i]);
    }

    return img
}

export const getItemsAsString = (items: object): string => {

    return String(items).replace(/,*$/, '')
}
export const degreesToRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
}

export const checkStringSquareBrackets = (value: string): { hasSquare: boolean, string: string, key?: string[] } => {
    const numBrackets = (value.match(/\[.*?\]/g) || []).length;
    const keys = value.split(/\[|\]/).filter(Boolean);
    const result = keys[0]
    delete keys[0]
    var hasSquare = false

    if (numBrackets > 0) {
        hasSquare = true
        const filteredArr = keys.filter(str => str !== '');
        return {
            hasSquare: hasSquare,
            key: filteredArr,
            string: result
        }
    }

    return {
        hasSquare: hasSquare,
        string: value
    }

}
export const getFinalPayload = (payload: DynamicObject): DynamicObject => {

    const filterKeys = ['workflowId', 'time_spent_ms', 'currentStep']

    for (var i = 0; i < filterKeys.length; i++) {
        let key = filterKeys[i]
        delete payload[key]
    }

    var finalPayload: DynamicObject = {}


    for (const value in payload) {
        let val = checkStringSquareBrackets(value)

        let mainKey = val.string
        if (!val.hasSquare) {
            finalPayload = {
                ...finalPayload,
                [`${mainKey}`]: payload[value]
            }

        } else {

            if (val.key?.length === 1) {
                finalPayload = {
                    ...finalPayload,
                    [`${mainKey}`]: {
                        ...finalPayload[mainKey],
                        [`${val.key}`]: payload[value]
                    }
                }
            } else {

                finalPayload = {
                    ...finalPayload,
                    [`${value}`]: payload[value]
                }
            }

        }
    }
    return finalPayload
}

export const getCurrentLocation = () => {
    return new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => resolve(position),
        err => reject(err)
    ));
}


export const extractComponentValue = (form: DynamicObject, identifier: string) => {

}

export const getFieldType = (format: string): string => {
    let type = ""
    switch (format) {
        case "all":
        case "open":
        case "alphanum":
            type = "text"
            break;
        default:
            type = "number"
    }
    return type
}


export function removeMatchingKeys(keys: string[], obj: object): void {
    for (const key of keys) {
        if (key in obj) {
            delete obj[key];
        }
    }
}

export function parentCallBack(msg): void {
    window?.parent?.postMessage?.(msg, '*')
    // window.parent.dispatchEvent(new Event(msg))
}

export function getUrlBasedOnCountry(country: string): string {
    const url = process.env.REACT_APP_JO_SOOQ_URL as string
    return url.replace("XX", country);
}

export const isIFrame = () => {
    try {
        return window.self !== window.top
    } catch (e) {
        return false
    }
}
export const toEnDigit = (s) => {
    return s.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    // Detect all Persian/Arabic Digit in range of their Unicode with a global RegEx character set
        function (a) {
            return a.charCodeAt(0) & 0xf
        }     // Remove the Unicode base(2) range that not match
    )
}
export const getInputType = (format: string) => {
    if (format === 'float') {
        return 'decimal';
    } else if (format === 'int') {
        return 'tel';
    } else {
        return 'text';
    }
}

export const jsonSafeGet = <T>(jsonString, defaultData = {}): T => {
    let output = defaultData as T;
    try {
        output = JSON.parse(jsonString);
    } catch (e) {
        // do nothing.
    }
    return output;
};

//To check if an array is empty using javascript
export function arrayIsEmpty(array) {
    //If it's not an array, return FALSE.
    if (!Array.isArray(array)) {
        return false;
    }
    //If it is an array, check its length property
    if (array.length == 0) {
        //Return TRUE if the array is empty
        return true;
    }
    //Otherwise, return FALSE.
    return false;
}

export function getFlowData(config: DynamicObject = {}) {
    return {
        draftId: window.sessionStorage.getItem('draftId'),
        flow: getData(config)
    }
}

export function setSessionByKey(key: string, value: any) {
    sessionStorage.setItem(key, value)
}

export function removeSessionByKey(key: string) {
    sessionStorage.removeItem(key)
}

export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}


function getData(config: DynamicObject = {}) {
    const oldFlow = OLD_FLOW
    const newFlow = NEW_FLOW
    let currentFlow = newFlow
    const source = Cookies.get('source') || 'desktop'


    return newFlow

    //todo must check job in the first condition
    if (window.sessionStorage.getItem('flow-type') === 'job') {
        console.log('job here')
        return oldFlow
    }

    if (source === 'mobile')
        return oldFlow
    else
        return newFlow
}

export function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        return uri + separator + key + "=" + value;
    }
}

export const getOriginDomain = () => {
    var hostname = window.location.hostname; // Get the hostname from the current URL
    var parts = hostname.split('.'); // Split the hostname by periods
    if (parts.length > 2) { // Check if there is a subdomain
        hostname = parts.slice(1).join('.'); // Remove the subdomain
    }
    return hostname;
}

export const isAuthLoggedOut = (state : string) => {
    if(state == 'LoggedOut'){
        return true;
    }
    return false;
}

export const hasNoPassword = () => {
    if(!getDataStorage().userInfo || (getDataStorage().userInfo && !getDataStorage().userInfo.hasPassword)){
        return true;
    }
    return false;
}



/*-------------------------------------
 *            FUNCTIONS
 * ----------------------------------*/

 export const findChildLevelByTargetIdentifier = (node: any, targetIdentifier: string, parentIdentifier = 0): string | any => {
    if (node.identifier === targetIdentifier) {
        return `${parentIdentifier}`;
    }

    if (node.childs && node.childs.length > 0) {
        for (let i = 0; i < node.childs.length; i++) {
            const recursiveValue = findChildLevelByTargetIdentifier(node.childs[i], targetIdentifier, i);

            if (typeof recursiveValue === "string") {
                return `${parentIdentifier}-${recursiveValue}`;
            }
        }
    }

    return node;
};
export const init = (step: any, targetIdentifier: string): string | undefined => {
    // Check if step has children
    if (step?.childs && step.childs.length > 0) {
        for (let i = 0; i < step.childs.length; i++) {
            const recursiveValue = findChildLevelByTargetIdentifier(step.childs[i], targetIdentifier, i);

            if (typeof recursiveValue === "string") {
                return recursiveValue;
            }
        }
    }

    return undefined; // Target not found in the tree
};

export const checkOnBehalfLogInFlow = async () => {
    const isBehalfLogInFlow=!!Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';
    console.log("isBehalfLogInFlow",isBehalfLogInFlow)
    return isBehalfLogInFlow
}

type UtmCampaign = {
    cMedium: string;
    cName: string;
    cSource: string;
}
export const utmCampaignParams = () => {
    let utmData = sessionStorage.getItem('utmData')
    let utmObj: UtmCampaign = utmData ?  JSON.parse(utmData) : {}
    return { 
        cMedium: utmObj.cMedium || "web_open",
        cName: utmObj.cName || "direct_web_open",
        cSource: utmObj.cSource|| "opensooq"
    }
}

export const RTL_DIR_SWITCHER_IDENTIFIERS = [
    'Engine_Size_Field',
    'engineSize',
    'batteryCapacity',
    'batteryRange',
    'Battery_Capacity_Field',
    'Battery_Range_Field'
]

export const injectUtmsIntoWebviewUrl = (url: string) => {
    const utmParams = utmCampaignParams();
    const containsParam = url.indexOf('?') !== -1;
    return `${url}${containsParam ? '&' : '?'}cMedium=${utmParams.cMedium}&cName=${utmParams.cName}&cSource=${utmParams.cSource}`;
}
// import MenuItem from "@mui/material/MenuItem";
// import styles from './FieldWithUnit.module.css'
// import {Props, RefDataType} from './interfaces';
// import {useValidation} from "../../../../hooks";
// import {getSizes} from "../../../../helpers/functions";
// import PostContext from "../../../../storage/PostContext";
// import DynamicObject from "../../../../models/dynamic-object";
// import {FormHelperText, Select, SelectChangeEvent, TextField} from "@mui/material";
// import React, {ChangeEvent, CSSProperties, useContext, useEffect, useImperativeHandle, useState} from "react";
// import languageContext from "../../../../storage/LanguageContext";
// import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
//
// const FieldWithUnit = React.forwardRef<RefDataType, Props>((props, ref) => {
//
//     const {config, nextApi} = props
//     const postCtx = useContext(PostContext)
//     const {error, setValidationError} = useValidationNew()
//
//     const langCtx = useContext(languageContext)
//     const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
//
//     const [unit, setUnit] = useState<number | string>(postCtx.data.form[config.identifier]?.unit)
//     const [minUnit, setMinUnit] = useState<string>(postCtx.data.form[config.identifier]?.min)
//     const [maxUnit, setMaxUnit] = useState<string>(postCtx.data.form[config.identifier]?.max)
//     const [value, setValue] = useState<DynamicObject>({
//         unit: postCtx.data.form[config.identifier]?.unit,
//         minUnit: postCtx.data.form[config.identifier]?.min,
//         maxUnit: postCtx.data.form[config.identifier]?.max
//     })
//
//     useImperativeHandle<RefDataType, RefDataType>(ref, () => {
//         return {
//             getState: (): any => value,
//             setValidationError
//         }
//     })
//
//     useEffect(function () {
//         if (props.config?.units?.length > 0 && !unit) {
//             setUnit(props.config.units[0].id)
//             setValue({
//                 ...value,
//                 unit: props.config.units[0].id
//             })
//
//             postCtx.addToForm(config.identifier, {
//                 ...postCtx.data.form[config.identifier],
//                 unit: props.config.units[0].id
//             })
//
//         }
//     }, [props.config?.units])
//
//
//     {/*
//     -UNIT HANDLER
//     -- ON CHANGE UPDATE STATE & context
//     */
//     }
//
//     const addUnitHandler = (e: SelectChangeEvent<number | string>) => {
//         setUnit(e.target.value)
//         setValue({
//             ...value,
//             unit: e.target.value
//         })
//         postCtx.addToForm(config.identifier, {...postCtx.data.form[config.identifier], unit: e.target.value})
//     }
//
//
//     {/*
//     -MIN HANDLER ON CHANGE & ON BLUR
//     -- ON CHANGE UPDATE STATE
//     -- ON BLUR UPDATE CONTEXT
//     */
//     }
//     const minUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
//         if (props.config?.min?.format == "int") {
//             const regex = /^[0-9\b]+$/;
//             const minValue = e.target.value;
//             if (minValue === '' || regex.test(minValue)) {
//                 setMinUnit(e.target.value)
//                 setValue({
//                     ...value,
//                     minUnit: e.target.value
//                 })
//             }
//         } else {
//             const regex = /^[0-9.,]+$/;
//             const minValue = e.target.value;
//             if (minValue === '' || regex.test(minValue)) {
//                 setMinUnit(e.target.value)
//                 setValue({
//                     ...value,
//                     minUnit: e.target.value
//                 })
//             }
//         }
//
//
//     }
//     const minUnitOnBlurHandler = () => {
//         postCtx.addToForm(props.config.identifier, {...postCtx.data.form[config.identifier], min: minUnit})
//     }
//
//     {/*
//     -MAX HANDLER ON CHANGE & ON BLUR
//     -- ON CHANGE UPDATE STATE
//     -- ON BLUR UPDATE CONTEXT
//     */
//     }
//     const maxUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
//         if (props.config?.max?.format == "int") {
//             const regex = /^[0-9\b]+$/;
//             const maxValue = e.target.value;
//             if (maxValue === '' || regex.test(maxValue)) {
//                 setMaxUnit(e.target.value)
//                 setValue({
//                     ...value,
//                     maxUnit: e.target.value
//                 })
//             }
//         } else {
//             const regex = /^[0-9.,]+$/;
//             const maxValue = e.target.value;
//             if (maxValue === '' || regex.test(maxValue)) {
//                 setMaxUnit(e.target.value)
//                 setValue({
//                     ...value,
//                     maxUnit: e.target.value
//                 })
//             }
//
//         }
//
//     }
//     const maxUnitOnBlurHandler = () => {
//         postCtx.addToForm(props.config.identifier, {...postCtx.data.form[config.identifier], max: maxUnit})
//     }
//
//     {/*TAGS*/
//     }
//     const minStyle = {
//         color: config?.min?.placeholder.color,
//         fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
//         fontSize: getSizes(config?.min?.placeholder.style?.size),
//     } as CSSProperties
//
//     const maxStyle = {
//         color: config?.min?.placeholder.color,
//         fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
//         fontSize: getSizes(config?.min?.placeholder.style?.size),
//     } as CSSProperties
//
//     const units = config.units.map((unit, i) => {
//         return <MenuItem value={unit.id}>
//             <span> {unit.label}</span>
//         </MenuItem>
//     })
//
//
//     const header = <div className={`${styles.header}`}>
//         <Select labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 value={parseInt(unit?.toString())}
//                 onChange={(e: SelectChangeEvent<number>) => addUnitHandler(e)}
//                 sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}>
//             {units}
//         </Select>
//     </div>
//
//     const min = <TextField className={`${styles.min}`} type={"number"} id="outlined-number"
//                            placeholder={config.min?.placeholder.locale} value={minUnit} onChange={minUnitHandler}
//                            onBlur={minUnitOnBlurHandler}
//         //label={"min"}
//                            InputLabelProps={{sx: minStyle}}/>
//
//     const max = <TextField className={`${styles.max}`} type={"number"} id="outlined-number"
//                            placeholder={config.max?.placeholder.locale}
//                            onChange={maxUnitHandler}
//                            onBlur={maxUnitOnBlurHandler}
//                            InputLabelProps={{sx: maxStyle}}
//                            value={maxUnit}/>
//
//     return (
//         <div
//             className={`${styles.container}`}>
//             {config.units.length > 0 && header}
//             <div className={`${styles.body}`}>
//                 {config.min && min}
//                 {config.max && max}
//             </div>
//
//             {error && <FormHelperText style={{
//                 color: "red",
//                 fontSize: "15px",
//                 paddingTop: "2px",
//                 textAlign: alignCenter
//
//             }}>
//                 {`*  ${error}`}
//             </FormHelperText>}
//         </div>
//     )
// })
//
// export default FieldWithUnit
//


import MenuItem from "@mui/material/MenuItem";
import styles from './FieldWithUnit.module.css'
import {Props} from './interfaces';
import {getInputType, getSizes, toEnDigit} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";
import {Select, SelectChangeEvent, TextField} from "@mui/material";
import React, {ChangeEvent, CSSProperties, useContext, useEffect, useState} from "react";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSearch from "../../../../hooks/use-search/use-search";
import {getDataStorage} from "../../../../helpers/storage.helper";


const FieldWithUnit = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<DynamicObject>({
        ref,
        config: props.config,
        autoSaveDelay: 3000
    })

    const [unit, setUnit] = useState<number | string>(state?.value ? state?.value['unit']?.toString() : parseInt(props?.config?.units && props?.config?.units[0]?.toString()))

    const {config, nextApi} = props
    const storage = getDataStorage()


    const inputMinType = getInputType(props.config?.min?.format ? props.config?.min?.format : '')
    const inputMaxType = getInputType(props.config?.max?.format ? props.config?.max?.format : '')


    document.addEventListener("wheel", function (event) {
        document.getElementById("outlined-number")?.blur()

    });

    useEffect(() => {
        validation.clear()
    }, [state.value ? state.value['min' || 'max'] : state.value])


    useEffect(function () {
        if (!state.value)
            setUnit(props.config?.units[0]?.id)

    }, [])

    useEffect(() => {
        if (state?.value?.min || state?.value?.max) {
            state.set({...state.value, unit: unit})
        }
    }, [state?.value?.min, state?.value?.max])


    const addUnitHandler = (e: SelectChangeEvent<number | string>) => {
        setUnit(e.target.value)
        state.set({...state.value, unit: e.target.value})
        // postCtx.addToForm(config.identifier, {...postCtx.data.form[config.identifier], unit: e.target.value})
    }

    const handleMaxKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.max?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else if (props.config?.max?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else if (props.config?.max?.format == 'all' || props.config?.max?.format == 'open') {
            state.set({
                ...state.value, max: toEnDigit(event.target.value)
            })
        } else {
            event.preventDefault();
        }
    }

    const handleMinKey = (event) => {
        let regExp = new RegExp(/[\u0660-\u0669\u06f0-\u06f9]/g)
        if (props.config?.min?.format == 'float' && (event.which >= 48 && event.which <= 57 || event.which == 46 || regExp.test(event.key))) {
            if (event.target?.value.includes('.') && event.which == 46) {
                event.preventDefault()
            }
            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })
        } else if (props.config?.min?.format == 'int' && (event.which >= 48 && event.which <= 57 || regExp.test(event.key))) {
            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })
        } else if (props.config?.min?.format == 'all' || props.config?.min?.format == 'open') {
            state.set({
                ...state.value, min: toEnDigit(event.target.value)
            })
        } else {
            event.preventDefault();
        }
    }


    {/*
    -MIN HANDLER ON CHANGE & ON BLUR
    -- ON CHANGE UPDATE STATE
    -- ON BLUR UPDATE CONTEXT
    */
    }
    const minUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.config?.min?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, min: toEnDigit(e.target.value)
                })
            }
        } else if (props.config?.min?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, min: toEnDigit(e.target.value)
                })
            }
        } else {
            state.set({
                ...state.value, min: toEnDigit(e.target.value)
            })
        }

    }


    {/*
    -MAX HANDLER ON CHANGE & ON BLUR
    -- ON CHANGE UPDATE STATE
    -- ON BLUR UPDATE CONTEXT
    */
    }
    const maxUnitHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.config?.max?.format == 'float') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*\.?[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, max: toEnDigit(e.target.value)
                })
            }
        } else if (props.config?.max?.format == 'int') {
            if (/^[\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]+$/g.test(e.target.value) || e.target.value == '') {
                state.set({
                    ...state.value, max: toEnDigit(e.target.value)
                })
            }
        } else {
            state.set({
                ...state.value, max: toEnDigit(e.target.value)
            })
        }
    }

    {/*TAGS*/
    }
    const minStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    const maxStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    const units = config.units.map((unit, i) => {
        return <MenuItem value={unit.id}>
            <span> {unit.label}</span>
        </MenuItem>

    })


    const header = <div className={`mt-auto`}>
        <Select labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parseInt(unit?.toString())}
                IconComponent={ExpandMoreIcon}
                onChange={(e: SelectChangeEvent<number>) => addUnitHandler(e)}
                sx={{boxShadow: 'none', borderRadius: "8px"}}
                MenuProps={{sx: {borderBottom: '1px solid black'}}}

        >
            {units}
        </Select>
    </div>

    const min = <TextField className={`${styles.min}`} id="outlined-number"
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           placeholder={config.min?.placeholder.locale} value={state.value ? state.value['min'] : ''}
                           onChange={minUnitHandler}
                           InputLabelProps={{sx: minStyle}}
                           inputMode={inputMinType}
                           onPaste={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCopy={(event) => {
                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={handleMinKey}


    />

    const max = <TextField className={`${styles.max}`} id="outlined-number"
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           placeholder={config.max?.placeholder.locale}
                           onChange={maxUnitHandler}
                           InputLabelProps={{sx: maxStyle}}
                           inputMode={inputMaxType}
                           onPaste={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }

                           }}
                           onCopy={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           value={state.value ? state.value['max'] : ''}
                           onKeyPress={handleMaxKey}

    />

    return (
        <>
            <div id={props.config.identifier}
                 className={`${storage.isMobile ? styles.mobileContainer : styles.container} `}>
                <div className={`${styles.body}`}>
                    {config.units.length > 1 ? header : config.units.length == 1 ? <div className={`${styles.header}`}>
                        <span> {props.config.units[0].label}</span>
                    </div> : ''}
                    {config.min && min}
                    {config.max && max}

                </div>
            </div>
            <ValidationError validation={validation}/>
        </>
    )
})

export default FieldWithUnit








export const OK = 200
export const PAGE_NOT_FOUND = 404
export const REFRESH_PAGE = 'refresh_page'
export const SUCCESS_PAGE='success'
export const FAILED_PAGE='failed'
export const CATEGORY_PAGE='category'
export const SUBCATEGORY_PAGE='subcategory'
export const PENDING_PAYMENT_PAGE='pending-payment'
export const PENDING_PAGE='pending'
export const DEFAULT_NPS='defaultNPS'
export const CLASSIC_NPS='classicNPS'
export const PAYMENT_METHODS_ONLINE='PaymentMethodsOnline'
export const UNEXLUDED_COUNTRY_IN_PAYMENT_SURVEY='ly'
export const SURVEY='survey'
import React, {FunctionComponent, FunctionComponentElement, Ref, useContext, useEffect, useMemo, useRef, useState} from "react";
import * as flow from './Flow/PreviewTools/index'
import DynamicObject from "../models/dynamic-object";
import NextButton from "./Flow/NextButton/NextButton";
import PublishApi from "../api/puplish.api";
import {useHttp, useQueryParams} from "../hooks";
import {AxiosError, AxiosResponse} from "axios";
import {Args, UseHttpResponse} from "../hooks/use-http/interfaces";
import PostContext, {flowLevels, flowRefs} from "../storage/PostContext";
import LanguageContext from "../storage/LanguageContext";
import UIContext, {AFTER_LOADING_METHODS} from "../storage/UIContext";
import {
    checkOnBehalfLogInFlow,
    getUrlBasedOnCountry, isIFrame,
    isNumeric,
    parentCallBack,
    removeMatchingKeys
} from "../helpers/functions";
import ActiveRequests from "../models/active-requests";
import PendingUploads from "./PendingUploads/PendingUploads";
import {getDataStorage} from "../helpers/storage.helper";
import SectionCacher from "../models/section-cacher";
import {REFRESH_PAGE} from "../constants/Status";
import Render from "./Flow/PreviewTools/Render";
import FlowReferences from "../flow-references";
import { logAddListingSubmit, logAddListingSuccess, logApplyCvSuccess, logEditListingSubmit, logEditListingSuccess, logLimitListingAccount, logLimitListingCategory, logLimitListingSubCategory } from "../logging/helpers/commonLoggingFunctions";
import { updateScreenHistory } from "../logging/helpers/updateScreenHistory";
import { EnumActionNameLevel3, Post } from "../logging/types/LoggingTypes";
import { toastify } from "./tostify/snackbarAlert";
import Cookies from "js-cookie";
import {FUNNEL_EVENTS, pushEventsToDataLayer, trackEvent} from "../helpers/Gtm";


interface Config {
    [key: string]: any
}


interface Props {
    config: Config,
    setConfigData: React.Dispatch<React.SetStateAction<any>>;
}


interface RefType {
    getState: () => DynamicObject
}


interface ValidatePublish {
    isValid: boolean,
    invalidElement?: {
        component: FunctionComponent,
        props: DynamicObject
    }
}

const DEFAULT_VALIDATE_PUBLISH: ValidatePublish = {
    isValid: true
}


const Mapper = (props: DynamicObject) => {
    const {loadData} = props
    const [config, setConfig] = useState<DynamicObject>(props.config)


    const levels = props.levels as number[]
    const postCtx = useContext(PostContext)
    const identifier = config.identifier
    const attributes: DynamicObject = {}
    flowRefs[config.identifier] = React.useRef<any>()
    const divRef = useRef(null);
    const params = useQueryParams()
    let scrollByIdentifier = params.query.get("scrollByIdentifier");
    {/*PREPARE REF*/
    }
    const executeScroll = () => {
        divRef.current?.scrollIntoView({ behavior: "smooth", block: "start"})
            setTimeout(()=>
            params.replaceURL("scrollByIdentifier", "")
            , 500)
    }
      useEffect(() => {
            if(divRef.current)
            setTimeout(() => {
        if(scrollByIdentifier)
                executeScroll();
            }, 10);
      }, []); 

    useEffect(() => {
        setConfig(props.config)
    }, [props.config])
    
    attributes.loadData = loadData
    return (
        <> {config?.identifier == scrollByIdentifier &&  <span ref={divRef}></span>}
        <Render setConfigData={props.setConfigData} config={config as any} {...attributes} levels={levels}
                setConfig={setConfig}
                values={postCtx.data.form[identifier]}>
            <>
                {config.childs && config.childs.map((child: Config, index) => {
                    const newProps = {
                        ...props,
                        config: {...child},
                        levels: [...levels, index],
                        setConfigData: props.setConfigData
                    }

                    return <>{child.identifier == scrollByIdentifier &&  <span ref={divRef}></span>}<Mapper {...newProps} /></>
                })}
            </>
        </Render>
        </>
    )
}


const MapperPreview = (props: Props): JSX.Element => {
    const {config} = props
    const postCtx = useContext(PostContext)
    const langCtx = useContext(LanguageContext)
    const [validatePublish, setValidatePublish] = useState<ValidatePublish>(DEFAULT_VALIDATE_PUBLISH)
    const storage = getDataStorage()
    const containerWidth = document.getElementById('content-container')
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);


    useMemo(function () {
        for (const key in postCtx.data.form) {
            const value = postCtx.data.form[key]
            FlowReferences.load(key, {
                state: {
                    get: () => value
                }
            })
        }
    }, [postCtx.data.form])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once

    const configPublishApi = {
        callback: PublishApi,
        initialData: {},
        withLoader: true
    }
    const publishApi = useHttp<DynamicObject>(configPublishApi)
    const request = publishApi.request
    const uiCtx = useContext(UIContext)

    function injectState(key: string, data: DynamicObject, state) {
        const keys = key.match(/\b(\w+)\b/g) || []
        if (keys.length > 1) {
            let mainKey = keys[0] || ''
            if (!data[mainKey])
                data[mainKey] = {}

            for (let i = 1; i < keys.length; i++) {
                let name = keys[i]
                data[mainKey][name] = state
            }
        } else {
            if (typeof state === "object" && !Array.isArray(state) && state !== null) {
                if (!data[key])
                    data[key] = {}                
                removeMatchingKeys(Object.keys(state), data[key])
                data[key] = {...data[key], ...state}
            } else {
                data[key] = state
            }

        }
    }

    function loadData() {
        const data: DynamicObject = {}
        for (const key in flowRefs) {
            const ref = flowRefs[key]
            if (!ref?.current?.state?.get)
                continue
            injectState(key, data, ref.current.state.get());
        }


        FlowReferences.map((identifier, field) => {
            if (field?.state && field?.state?.get())
                injectState(identifier, data, field.state.get());
        })
        return data;
    }

    useEffect(() => {
        return () => {
            SectionCacher.clearInstance()
        }
    }, [])

    {/*RECURSION FUNCTION TO GET CHILD'S AND SUB COMPONENT INSIDE PREVIEW STEP */
    }

    const logLeadingToService = (payload : any)=>{
        if(!payload)
            return;

        const neighborhood = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.Neighborhood : '';

        const cityField = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.City : '';
        
        const categoriesSub = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.Subcategory : '';

        const categoriesMain = config && config.GA && config.GA.cd && config.GA.cd.Category ? config.GA.cd.Category : '';

        logAddListingSuccess({
            id: payload.post_id,
            neighborhoodName: neighborhood,
            city: cityField,
            category: { label: categoriesMain},
            subCategory: categoriesSub,
            service: '',
        }, 200);
    }

    const logClickingSubmit = (payload : any)=>{
        if(!payload)
            return;
        
        const categoriesSub = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.Subcategory : '';
        const categoriesMain = config && config.GA && config.GA.cd && config.GA.cd.Category ? config.GA.cd.Category : '';

        logAddListingSubmit({
            id: payload.post_id,
            category: { label: categoriesMain},
            subCategory: categoriesSub,
            service: '',
        });
    }

    const overLimitLog = (overLimitType : string, post: Post)=>{
        switch(overLimitType)
        {
            case EnumActionNameLevel3.CATEGORY:
                logLimitListingCategory(post, 200);
                break;
            case EnumActionNameLevel3.SUB_CATEGORY:
                logLimitListingSubCategory(post, 200);
                break;
            case EnumActionNameLevel3.ACCOUNT:
                logLimitListingAccount(post, 200);
                break;
            default:
                break;
        }
    }

    publishApi.request = (args?: Args) => {
        const data = args?.config?.data;
        const urlParams = new URLSearchParams(window.location.search)
        const flow = urlParams.get('flow');

        const neighborhood = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.Neighborhood : '';
        const cityField = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.City : '';
        const categoriesSub = config && config.GA && config.GA.cd && config.GA.cd.Subcategory ? config.GA.cd.Subcategory : '';
        const categoriesMain = config && config.GA && config.GA.cd && config.GA.cd.Category ? config.GA.cd.Category : '';
        const postId = urlParams.get('post_id') ?? '';
        let post = {
            id: parseInt(postId), 
            neighborhoodName: neighborhood, 
            city: {name: cityField}, 
            category: {label:categoriesMain}, 
            subCategory: categoriesSub, 
            service : '',
        }
        if(flow === 'edit' && isNumeric(postId))
        {   
            logEditListingSubmit(post)
        }
        const onSuccessPublishApi = (response: AxiosResponse) => {

            if(sessionStorage.getItem('loggingFlow') && (sessionStorage.getItem('loggingFlow') === 'draft' || sessionStorage.getItem('loggingFlow') === 'republish')){
                trackEvent(FUNNEL_EVENTS.SUCCESS);
                pushEventsToDataLayer();
            }else if(postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post'){
                trackEvent(FUNNEL_EVENTS.SUCCESS);
                pushEventsToDataLayer();
            }

            if(response && response.data && response.data.post && response.data.post.overLimitType)
            {
                overLimitLog(response.data.post.overLimitType, post);
            }
            if (flow === 'jobApply') {
                post = {
                    id: parseInt(postId), 
                    neighborhoodName: '##MISSING##', 
                    city: {name: '##MISSING##'}, 
                    category: {label:'##MISSING##'}, 
                    subCategory: '##MISSING##', 
                    service : '',
                }
                updateScreenHistory('listing_view')
                logApplyCvSuccess(post)
                parentCallBack('job_applied')
                const baseUrl = getUrlBasedOnCountry(storage.country)
                const url = `${baseUrl}/${urlParams.get('lang')}/job-posters/${urlParams.get('post_id')}?applied=${true}`
                return window.top.location.href = url
            }
            if(urlParams.get('flow') === 'edit')
            {   
                const postId = response?.data?.draft?.payload?.post_id
                if(isNumeric(postId))
                {
                    logEditListingSuccess(post)
                }
            }

            //logging
            if(response && response.data && response.data.draft && response.data.draft.payload && response.data.draft.payload.post_id){   
                try{
                    logLeadingToService(response.data.draft.payload);
                }catch(err){
                    console.log(err);
                }
            }
            if(postCtx.data.flow_type === "survey"){
                const baseUrl = getUrlBasedOnCountry(storage.country)
                const url = `${baseUrl}`
                return window.top.location.href = url
            }
            if(!!Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity'){
                const baseUrl = process.env.REACT_APP_INFINITY_URL;
                const url = `${baseUrl}/post/add-listing-success?id=${response.data?.draft?.payload?.post_id}&memberId=${storage.userInfo.id}`
                return window.top.location.href = url
            }
            parentCallBack('without_confirm_dialog')
            parentCallBack(REFRESH_PAGE)
            uiCtx.setLoading(false)
            postCtx.updateStep({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                post_id: response.data?.draft?.payload?.post_id,
                overLimitType: response.data?.post?.overLimitType,
                isOverLimit: response.data?.post?.overLimitType,
                formType:response?.data?.review_identifier
            })
        }

        const onError = (error: AxiosError) => {
            uiCtx.setLoading(false)
            if (error.response?.status === 422) {
                AFTER_LOADING_METHODS.push(() => {
                    const data = error.response?.data as DynamicObject
                    const hasPropertyPost_id = data?.result?.errors.some(obj => obj.field === 'post_id')
                    if (urlParams.get('flow') == 'jobApply' && !hasPropertyPost_id) {
                        return
                    }
                    for (const key in data?.result?.errors) {
                        const errorObject = data?.result?.errors[key] as DynamicObject                        
                        if(!errorObject.field || errorObject.field == "post_id") { 
                            toastify({toastType: "error" ,description : errorObject?.message ?? 'Something Went Wrong!', locale : langCtx.language});
                            continue;
                        }
                        const ref = FlowReferences.get(errorObject.field)
                        console.log(ref)
                        if (!ref?.validation?.set)
                            continue

                        if (key == "0") {
                            let element = document.getElementById(errorObject.field)
                            element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                        ref.validation.set(errorObject?.message)
                    }
                })
            }
        }

        // console.log(FlowReferences.reRenderedFlow, flowLevels[], 'reRenderedFlow')

        let step = postCtx.previewStep as DynamicObject
        for (const key in FlowReferences.reRenderedFlow) {

            const updateConfigByLevel = (config, levels) => {
                if (!levels.length && FlowReferences.get(key) && FlowReferences.get(key)?.config) {
                    return FlowReferences.get(key).config.get()
                }

                const level = levels.shift()
                config.childs[level] = updateConfigByLevel({...config.childs[level]}, [...levels])
                return config
            }

            // for (const level of flowLevels[key]) {
            //     config = config.childs[level]
            // }

            step = updateConfigByLevel({...step}, [...flowLevels[key] || []])
        }

        postCtx.updateData({
            form: {
                ...data,
            },
            step
        })

        postCtx.updatePreviewStep(step)

        //to check if it's on behalf log in
        const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';

        
        return request({
            ...args,
            config: {
                ...args?.config,
                flow_type: postCtx.data.flow_type,
                formType:postCtx.data.formType,
                language: langCtx.language,
                workflowId: postCtx.data.workflow_id,
                data: {
                    ...args?.config?.data,
                    ...data,
                    draftId: postCtx.data.draft_id,
                    member_id:isBehalfLogIn?storage?.userInfo?.id:'',
                    time_spent_ms: Date.now() - postCtx.data.start_time,
                }
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccessPublishApi,
                error: onError,
                before: () => {
                    console.log(postCtx.data.form, 'cvcvvcv', FlowReferences.fields['categoriesMain'])
                    uiCtx.setLoading(true)
                }
            }
        })
    }


    useEffect(() => {
        if (!uiCtx.errors.length)
            return

        // for (const key in uiCtx.errors) {
        //     const errorObject = uiCtx.errors[key] as DynamicObject
        //
        //     const ref = flowRefs[errorObject.field]
        //
        //
        //     if (!ref?.current?.validation?.set)
        //         continue
        //
        //     if (key == "0") {
        //         let element = document.getElementById(errorObject.field)
        //         element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        //     }
        //     ref.current.validation.set(errorObject?.message)
        // }
        return () => {
            // uiCtx.setErrors([])
        }
    }, [uiCtx.errors])


    const x = config.submit ? <div><NextButton
        style={{
            bold: config.submit?.style?.bold,
            size: config.submit?.style?.size ?? 'medium',
            color: config.submit?.color ?? 'red',
            backgroundColor: config.submit?.background.color ?? 'white',
            width: storage.isMobile ? `${screenWidth - 10}px` : "400px"
        }}
        nextApi={publishApi}
        onClick={(api: UseHttpResponse<DynamicObject>) => {
            if(sessionStorage.getItem('loggingFlow') && (sessionStorage.getItem('loggingFlow') === 'draft' || sessionStorage.getItem('loggingFlow') === 'republish')){
                trackEvent(FUNNEL_EVENTS.SUBMIT);
            }else if(postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post'){
                trackEvent(FUNNEL_EVENTS.SUBMIT);
            }
            const data = loadData()
            //logging
            logClickingSubmit(data);
            //
            api.request = api.request.bind(this, {config: {data}})

            if (!ActiveRequests.getInstance().uploadsCounter()) {
                return api.request()
            }

            const attributes: DynamicObject = {
                publishApi: api,
                totalRequests: ActiveRequests.getInstance().uploadsCounter(),
                lang: langCtx.language
            }

            setValidatePublish({
                isValid: false,
                invalidElement: {
                    component: PendingUploads as FunctionComponent,
                    props: {...attributes}
                }
            })

            const PreviousButton = document.getElementById("PreviousButton")
            if (PreviousButton)
                PreviousButton.style.display = "none"
        }
        }
        isSticky={props.config?.submit?.sticky}

        // isSticky={config.submit?.sticky}
    >{config.submit?.locale ? config.submit?.locale : "Next"} </NextButton>
    </div> : []


    if (!validatePublish.isValid && validatePublish.invalidElement) {

        const ValidateComponent = validatePublish.invalidElement.component
        return React.createElement(ValidateComponent as FunctionComponent, {...validatePublish.invalidElement.props})
    }


    {/*
    - THIS IS CHILD'S FOR PREVIEW STEP
    - MAPPING FROM FOLDER PREVIEW TOOLS
    */
    }
    const components = config.childs.map((child: Config, index: number) => {
        // return Mapper(child, [index])
        return <Mapper config={child} loadData={loadData} levels={[index]} setConfigData={props.setConfigData}/>
    })


    components.push(x)

    return components

}

export default MapperPreview
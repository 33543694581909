import React, {useEffect} from "react";
import styles from './Modal.module.css'
import Overlay from "../Overlay/Overlay";
import {IoIosClose} from "react-icons/io";
import {TextProperty} from "../../../models/general";
import DynamicObject from "../../../models/dynamic-object";

interface Props {
    children?: React.ReactNode;
    title?: TextProperty | undefined,
    setIsOpen: (value: any) => void,
    footer?: React.ReactNode,
    styles?: DynamicObject
}

const Modal = (props: Props) => {
    const {title, setIsOpen} = props
    let {styles: stylesProps = {}} = props

    const closeHandler = () => {
        setIsOpen(false)
    }

    stylesProps = {
        modal: stylesProps.modal || {},
        content: stylesProps.content || {}
    }


    useEffect(() => {
        document.body.classList.add(`${styles.overflowHidden}`)
        return () => {
            document.body.classList.remove(`${styles.overflowHidden}`)
        }
    }, [])

    return (
        <Overlay>
            <div className={styles.container} onClick={closeHandler}>
                <div className={styles.model} style={stylesProps.modal} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <h3 style={{color: title?.color}}>{title?.locale}</h3>
                        <span><IoIosClose className={"cursor__pointer"} style={{height: "25.2px", width: "25.85px"}}
                                          onClick={closeHandler}/></span>
                    </div>
                    <div style={stylesProps.content} className={styles.content}>
                        {props.children}
                    </div>
                    {props.footer}
                </div>
            </div>
        </Overlay>
    )
}

export default Modal